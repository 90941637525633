import React from 'react';
import Section from '../common/Section';
import styles from '../../styles/modules/components/cities-section.module.scss';
import Image from '../common/Image';
import { getCityDisplayName, getSelectedCityFromCookie, recentlySetCookie, setSelectedCityInCookie } from '../../utils/cities';
import { combineParamsString, getUtmString } from '../../utils';
import { setCityChangePopUpAction } from './../../redux/actions/genericActions';
import { connect } from 'react-redux';
import CITIES_SLUG from '../../city_constants';
import { isAmcInstaCart } from '../../utils/cart/index'

interface CityState {
    toggle:any,
    isBrand:any,
    isCities:any,
    isLocalities:any
}


class Cities extends React.Component<any, CityState>{

    constructor(props) {
        super(props);
        this.state = {
            toggle: 'cities',
            isBrand: false,
            isCities: false,
            isLocalities: false
        }
    }

    componentDidMount = () => {
        if(this.props.brands_display?.brands?.length>0){
            this.setState({isBrand:true})
        }
        if(this.props.slug!='home-repair-maintenance-plans' && this.props.slug_mapping?.cities_covered?.length>0){
            this.setState({isCities:true})
        }else{
            if(this.props.cities && this.props.cities.length>0){
                this.setState({isCities:true})
            }
        }
        if(this.props.popular_localities?.localities?.length>0){
            this.setState({isLocalities:true})
            this.setState({toggle:'localities'})
        }
    }


    setCity = (item) => {
        let cookie_city = getSelectedCityFromCookie()
        let selected_city = item.name && CITIES_SLUG[item.name].city
        if(this.props.cart.quantity && isAmcInstaCart(this.props.cart) && cookie_city !== selected_city){
            this.props.setCityChangePopUpAction({state:true,city_data:item})
        }else{
            this.props.router.push(combineParamsString(item.url, getUtmString()))
            setSelectedCityInCookie(item.name)
            recentlySetCookie()
        }
      }

    render() {
        return (
            <Section className={styles.city__div} hasPadding={true} id={this.state.isLocalities?"localities":"cities"} inverted={false} title={`${this.props.headings} Coverage Details` } dataBg={"shade-2"}>
                <div className='container'>
                    <div className={`${styles.city__cat}`}>
                        {this.state.isLocalities && <><a className={this.state.toggle==='localities'? `${styles.city__enabled} ${styles.city__cities}` : `${styles.city__disabled} ${styles.city__cities}`} onClick={() => { this.setState({ toggle: 'localities' }) }}>Localities</a><p className={`${styles.city__pipe}`}> | </p></>}
                        {this.state.isCities && <> <a className={this.state.toggle==='cities' ? `${styles.city__enabled} ${styles.city__cities}` : `${styles.city__disabled} ${styles.city__cities}`} onClick={() => { this.setState({ toggle: 'cities'}) }}>Cities</a>{this.state.isBrand && <p className={`${styles.city__pipe}`}> | </p> }</>}
                        {this.state.isBrand && <> <a className={this.state.toggle==='brands' ? `${styles.city__enabled} ${styles.city__cities}` : `${styles.city__disabled} ${styles.city__cities}`} onClick={() => { this.setState({ toggle: 'brands' }) }}>Brands</a> </>}
                    </div>
                    {/* <div className={`${styles.city__cat}`}>
                    {this.props.brands_display && Object.keys(this.props.brands_display).length !== 0 ?
                        <a className={!this.state.toggle ? `${styles.city__enabled} ${styles.city__cities}` : `${styles.city__disabled} ${styles.city__cities}`} onClick={() => { this.setState({ toggle: false }) }}>Cities</a>
                        :
                        <a className={`${styles.city__enabled}`} onClick={() => { this.setState({ toggle: false }) }}>Cities</a>
                    }
                        {this.props.brands_display && Object.keys(this.props.brands_display).length !== 0 ? <><p className={`${styles.city__pipe}`}> | </p>
                            <a className={!this.state.toggle ? `${styles.city__disabled} ${styles.city__brands}` : `${styles.city__enabled} ${styles.city__brands}`} onClick={() => { this.setState({ toggle: true }) }}>Brands</a></> : ""}
                    </div> */}
                    {this.state.toggle==='localities' && 
                        <div className={styles.localities} itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                            <div className={styles.heading} >
                                <div className={styles.location_img}><Image src={"/static/images/location/location.png"} alt="Whatsapp" unoptimized="true" width="24" height="24" /></div>
                                <h2>{this.props.popular_localities?.title}</h2>
                            </div>
                            <div className={styles.localities__list}>
                                {this.props.popular_localities && this.props.popular_localities.localities?.map((item, index) => {
                                    let locality = item.split(',')[0]
                                    let city = item.split(',')[1]
                                    return (
                                        <span className={styles.localities_name} key={index}>
                                            {locality && <div  itemProp="addressLocality" key={`city-${item}`}>{locality}{city?',':''}&nbsp; </div>}
                                            {city && <div  itemProp="addressRegion" key={`city-${item}`}>{city}</div>}
                                        </span>
                                    )
                                    }
                                )}
                                <span className={styles.localities_name} itemProp="addressLocality" key={`city-more}`}>& More</span>
                            </div>
                        </div>
                    }           
                    {this.state.toggle==='cities' && (this.props.slug!='home-repair-maintenance-plans' ?
                        <div className={this.props.slug_mapping?.length>=6 ? `${styles.city__list}` : `${styles.city__list} ${styles.cities_center}`}>
                            {this.props.slug_mapping && this.props.slug_mapping.cities_covered && this.props.slug_mapping.cities_covered.map((item, index) => 
                                <span itemProp="address" itemScope itemType="https://schema.org/PostalAddress" className={styles.city_click} key={`brand-${index}-${item}`}>
                                    <a itemProp="addressRegion" onClick={()=>{this.setCity(item)}} href="#">{getCityDisplayName(item.name)}</a>
                                </span>)}
                        </div>
                        :
                        <div className={this.props.cities?.length>=6 ? `${styles.city__list}` : `${styles.city__list} ${styles.cities_center}`}>
                            {this.props.cities && this.props.cities.map((item, index) => 
                                <span itemProp="address" itemScope itemType="https://schema.org/PostalAddress" className={styles.city_click} key={`brand-${index}-${item}`}>
                                    <em itemProp="addressRegion">{getCityDisplayName(item)}</em>
                                </span>)}
                        </div>)
                    }
                    {this.state.toggle==='brands' && 
                        <div>
                            <h2 className={styles.heading}>{this.props.brands_display.title}</h2>
                        <div itemProp="brand" itemType="https://schema.org/Brand" itemScope className={this.props.brands_display.length>=6 ? `row ${styles.city__list}` : `${styles.city__list} ${styles.cities_center}`}>
                            {this.props.brands_display && this.props.brands_display.brands.map((item, index) => <span itemProp="name" key={`brand-${index}-${item} col-6 col-lg-12`}>{item}</span>)}
                            <span itemProp="name" key={`brand-end-more col-6 col-lg-12`}>& More</span>
                        </div>
                        </div>
                    }  
                </div> 
            </Section>
        )
    }
}

  
const mapDispatchToProps = (dispatch: any) => {
    return {
        setCityChangePopUpAction: (data:any) => dispatch(setCityChangePopUpAction(data)),
    }
}
  
export default connect(null, mapDispatchToProps)(Cities);

