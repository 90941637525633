import { AB_EXPERIMENT_CONFIG } from '../constants';
import Cookies from 'js-cookie'
import { TESTING_PAGE } from '../city_constants';
import { isMobile } from 'react-device-detect';


export const showCustomPage = (experiment) => {
    const cookie_second = Cookies.get(experiment)
    if (cookie_second && cookie_second % 2 == 0) {
        return true
    }
    return false
}

export const isCustomPage = (slug: any) => {
    if (isMobile && TESTING_PAGE.includes(slug)) {
        return true
    }
    return false
}

export const ABExperimentConfig = (experiment, slug) => {
    if (AB_EXPERIMENT_CONFIG.status && AB_EXPERIMENT_CONFIG[experiment]?.status) {
        if (experiment == 'rate_us_ab') {
            return isMobile && TESTING_PAGE.includes(slug)
        }
    }
    return false
}

export const getAbExperimentCookieExpiry = (experiment) => {
    return AB_EXPERIMENT_CONFIG[experiment]['cookie_expiry'] || 30;
}
