import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux'
import dynamic from "next/dynamic";
import {GetProducts} from '../../api/plan';
import {addToCart} from '../../redux/actions/cartActions';
import featureStyles from '../../styles/modules/common/features.module.scss';
import styles from "../../styles/modules/common/features.module.scss";
import {toggleRedirection} from '../../redux/actions/cartActions';
import { combineParamsString, getUtmString } from '../../utils';
import { isMobile } from "react-device-detect";
import { setScrollElementData } from '../../redux/actions/planActions'
import BannerWithGraphic from "../common/BannerWithGraphic";
import PlanDetailsSelection from "../../components/user-interaction/PlanDetailsSelection";
import PlanSelectionFields from "./PlanSelectionFields";
import Cities from "../cities-brands";
import { viewItemList } from "../../tracking/ecommerceTracking";
import { getPageURL, getPageViewData } from '../../utils/tracking';
import { productDetailScreenViewed } from '../../tracking/segment/planPage';
import { pageCallSegment } from '../../utils/tracking/segmentTracking';



interface State {
  plans: any,
  selected_plans: any
}


const LazyLoader = dynamic(()=> import("@/hoc/LazyLoader"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const Blog = dynamic(()=> import("../common/Blog"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const Faq = dynamic(()=> import("../common/Faq"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const FeatureImageBanner = dynamic(()=> import("../../components/common/FeatureImageBanner"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const Testimonial = dynamic(()=> import("./../common/Testimonials"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const CrossSell = dynamic(()=> import("../../components/cross-sell/CrossSell"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const NotSureWhatLooking = dynamic(()=> import('../../components/not-sure/NotSureWhatLooking'),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

class HomeCare extends Component<any, State> {
  state = {
    plans: [],
    selected_plans: [],
  }
  componentDidMount = () => {
    // trackPage("Category Page",{'categories':this.props.categories,'product_group':this.props.product_group,...getPageViewData()})
    pageCallSegment("Product Detail - Screen Viewed")
    productDetailScreenViewed({'categories':this.props.categories.join(','), 'product_group':this.props.product_group.join(','), ...getPageViewData()})
    GetProducts(
      {
        application:'website',
        channel_name:'website',
        product_group:'homecare',
      })
      .then((res:any)=>{
        if(res) {
          this.setState({plans:res.data.products})
          viewItemList(res.data.products, "HomeCare", "HomeCare Plan Cards")
        }
      })
  }
  componentDidUpdate = () => {
    if(this.props.cart.redirectToCart) {
      this.props.toggleRedirection()
      let redirectUrl = combineParamsString(`/cart/${this.props.cart.token_id}`, getUtmString())
      this.props.router.push(redirectUrl)
    }
    if(this.props.plans.showScrollAfterElement !== 'hc-required') {
      this.props.setScrollElementData('hc-required')
    }
  }
  render() {
    return (
      <Fragment>
        <BannerWithGraphic
          title={this.props.page_meta.banner_config ? this.props.page_meta.banner_config.title: ''}
          subtitle={this.props.page_meta.banner_config ? this.props.page_meta.banner_config.subtitle: ''}
          cta_button_title={this.props.page_meta.banner_config? this.props.page_meta.banner_config.cta_text: ''}
          onCtaClick={() => false}
          banner_img={this.props.page_meta.banner_config? this.props.page_meta.banner_config.image : ''}
          journeyType="HomeCare"
          categories={['HomeCare']}
          breadcrumb={this.props.breadcrumb?this.props.breadcrumb:[]}
        />
        <PlanDetailsSelection
          benefits={this.props.page_meta.features.plan_selection ? this.props.page_meta.features.plan_selection.benefits : []}
          specificBenefits={this.props.page_meta.features.plan_selection ? this.props.page_meta.features.plan_selection.plan_specific_benefits : []}
          specificImages={this.props.page_meta.features.plan_selection ? this.props.page_meta.features.plan_selection.plan_specific_images : {}}
          cities={this.props.page_meta.cities}
          PlanSelectionFields={PlanSelectionFields}
          router={this.props.router}
          plans={this.state.plans}
        />
        <LazyLoader>
        <div className='odd-even-start-with-white'>
          {this.props.page_meta.features.featuresImageBannerHC &&
            <FeatureImageBanner
              title={this.props.page_meta.features.featuresImageBannerHC !== undefined ? this.props.page_meta.features.featuresImageBannerHC.title : ''}
              withSubtitle={false}
              features={this.props.page_meta.features.featuresImageBannerHC !== undefined ? this.props.page_meta.features.featuresImageBannerHC.featuresOfPlan : ''}
              className={`${featureStyles.grey__neutral} ${featureStyles.how_to_enjoy_ac}`}
              isGreyBackground={false}
              isRoundShape={true}
              imageSizeClass={featureStyles.amc__circle_image}
              />
          }
          {this.props.page_meta.features.benefitsHC &&
            <FeatureImageBanner
              title={this.props.page_meta.features.benefitsHC !== undefined ? this.props.page_meta.features.benefitsHC.title : ''}
              withSubtitle={false}
              features={this.props.page_meta.features.benefitsHC !== undefined ? this.props.page_meta.features.benefitsHC.featuresOfPlan : ''}
              className={`${featureStyles.grey__neutral} ${featureStyles.how_to_enjoy_ac}`}
              isGreyBackground={false}
              isRoundShape={true}
              imageSizeClass={featureStyles.amc__circle_image}
              elementID={'hc-required'}
              />
          }
          {this.props.page_meta.features.stepsToProtectHC &&
            <FeatureImageBanner
              title={this.props.page_meta.features.stepsToProtectHC !== undefined ? this.props.page_meta.features.stepsToProtectHC.title : ''}
              withSubtitle={false}
              features={this.props.page_meta.features.stepsToProtectHC !== undefined ? this.props.page_meta.features.stepsToProtectHC.steps : ''}
              className={`${styles.grey} ${styles.how_to_enjoy_ac}`}
              isGreyBackground={isMobile == true}
              isRoundShape={true}
              imageSizeClass={styles.amc__circle_image}
            />
          }
          {this.props.page_meta.features.ourPromiseBannerHC &&
            <FeatureImageBanner
              title={this.props.page_meta.features.ourPromiseBannerHC !== undefined ? this.props.page_meta.features.ourPromiseBannerHC.title : ''}
              withSubtitle={false} features={this.props.page_meta.features.ourPromiseBannerHC !== undefined ? this.props.page_meta.features.ourPromiseBannerHC.steps : ''}
              className={`${styles.grey__neutral}`}
              isGreyBackground={isMobile == false}
            />
          }
          {
            this.props.testimonials && this.props.testimonials.length > 0 &&
              <Testimonial testimonial={this.props.testimonials} category={"HomeCare Basic"}/>
          }
          {
            this.props.blogs && this.props.blogs.length > 0 &&
              <Blog key="wp-blog" blog={this.props.blogs?this.props.blogs:[]} title="Tips & Common Solutions to Keep Your Devices Running" category={"HomeCare Basic"}/>
          }
          {
            this.props.faqs && this.props.faqs.length !== 0 ?
              <Faq title="Frequently Asked Questions" questions={this.props.faqs} className="faq-primary" category={"HomeCare Basic"}/>
              :
              null
          }
          <Cities headings={"HomeCare"} cities={this.props.page_meta.cities} slug={this.props.slug}/>
          {this.props.cross_sell.length !== 0 ?
            (<CrossSell list={this.props.cross_sell} router={this.props.router} category={"HomeCare Basic"}/>)
          :
            ''
          }
          {this.props.products ?
            <NotSureWhatLooking
              products={this.props.products}
              option={this.props.option}
              setOption={this.props.setOption}
              action={(e) => this.props.action(e)}
            /> : null}

        </div>
        </LazyLoader>
      </Fragment>
    )
  }
}

const mapStateToProps = (state:any) => {
  return {
      cart: state.cart,
      plans: state.plans,
  }
}

const mapDispatchToProps = (dispatch:any) => {
  return {
    addToCart: (data:any) => dispatch(addToCart(data)),
    toggleRedirection: () => dispatch(toggleRedirection()),
    setScrollElementData: (data: any) => dispatch(setScrollElementData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeCare);
