import React from 'react';
import styles from '../../../styles/modules/components/AMCWaterPurifier/why-should-you-get.module.scss'
import Section from '../../common/Section';
import Hidden from '@mui/material/Hidden';
import theme from '../../mui-overrides';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Image from '../../common/Image';

const WhyShouldYouGet = () => {
    return (
        <Section hasPadding={true}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div className="container">
                        <div className="row">
                            <Hidden lgUp>
                            <div className={`${styles.section_title} col-12 col-sm`}>
                                <h4>Why Should You Get Our REPURE Kit?</h4>
                            </div>
                        </Hidden>
                            <div className={`${styles.image} col-12 col-lg-6`}>
                              <Image src={"/static/images/AMCWaterPurifier/amc-filter-0.png"} alt="amc-filter" width="730" height="516" /> 
                            </div>
                            <div className="col-12 col-lg-6">
                                <Hidden lgDown>
                                    <div className={`${styles.section_title} `}>
                                        <h4>Why Should You Get Our REPURE Kit?</h4>
                                    </div>
                                </Hidden>
                                <div className={styles.features_list}>
                                    <ul>
                                        <li>The Repure Kit contains a Dupont FilmTec RO Membrane- World’s most trusted RO membrane, imported from the USA</li>
                                        <li>The Kit is dispatched to your house directly, ensuring complete safety of the consumables</li>
                                        <li>The filters are sourced from one of the largest manufacturers in India ensuring high quality consumables</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>   
        </Section>
    );
}

export default WhyShouldYouGet;
