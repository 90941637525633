import dynamic from 'next/dynamic';
import {isMobile} from 'react-device-detect';
import styles from '../../styles/modules/components/city-selector.module.scss';
import Image from '../common/Image';
import { combineParamsString, getStaticPath, getUtmString } from '../../utils';
import { canShowCitySelection, getCityDisplayName, recentlySetCookie, setSelectedCityInCookie, getCityOrder } from '../../utils/cities';
import { useRouter } from 'next/router';
import slug_mappings from '../../data/slug_mappings'

const UIModal = dynamic(()=> import('../common/ui-components/surface/UIModal'),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const CitySelector = (props: any) => {
  let city_count = props.slug_mapping['cities_covered']?.length;
  let imgSize = isMobile ? 64 : 100;
  let count_class_name = `count-${city_count}`
  let city_selector = <></>;
  const setCity = (city_name) => {
    setSelectedCityInCookie(city_name)
    recentlySetCookie()
  }
  const router = useRouter()

  const handleClick = (e,city) => {
    e.preventDefault();
    setCity(city.name);
    const slug:any =  router?.query.slug;
    if(slug_mappings[slug].is_generic){
      router.replace(`${combineParamsString(city.url, getUtmString())}`)
    }else{
      router.push(`${combineParamsString(city.url, getUtmString())}`)
    }
  }

  if(canShowCitySelection(props.slug_mapping)){
    city_selector = <UIModal isOpen isPlainBG isNonDismissable xlarge={true}>
      <div className={styles.city_selector}>
        <div className={styles.header}>
            <h3>{props.slug_mapping['device_name'] || props.device_name} Service & Repair</h3>
            <h4>Cities we cover</h4>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>Select a city from below</div>
          <div className={`${styles.city_list} ${styles[count_class_name]}`}>
          {
            getCityOrder(
              props.slug_mapping['cities_covered']
            ).map((city, index) => {
              return <>
                  <a href={`${combineParamsString(city.url, getUtmString())}`} onClick={(e) => handleClick(e,city)} title={city.name} className={styles.city} key={index}>
                    <div className={styles.icon}><Image src={getStaticPath(`/static/images/cities/${getCityDisplayName(city.name)}.png`)} width={imgSize} height={imgSize}/></div>
                    <div className={styles.city_name}>{getCityDisplayName(city.name)}</div>
                  </a>

                {index == 1 && <br className={styles.split2}/>}
                {index == 2 && <br className={styles.split3}/>}
              </>
            })
          }
          </div>
        </div>
      </div>
    </UIModal>
  }
  return city_selector;
}
export default CitySelector;
