import React from 'react';
import {GetProducts} from '../../../../api/plan';
import DeviceDetails from './DeviceDetails';
import PlanDetails from './PlanDetails';
import {appendQueryParams} from '../../utils';
import { toggleLoading,addToCart, toggleCartAlert, toggleResetStep, toggleRedirection } from '../../../../redux/actions/cartActions';
import { toggleSpecificFeatures, setCurrentStep, setAllPlans, setCrossSellPlans } from '../../../../redux/actions/planActions';
import { connect } from 'react-redux';
import { getParamsObjectFromString } from '../../../../utils';
import UIModal from '../../../common/ui-components/surface/UIModal';
import PlanAlert from '../../PlanAlert';
import {viewItem} from '../../../../tracking/ecommerceTracking';
import {selectWpTypeTrigger,selectWpBrand,viewPlanClick,selectPlanClick,addToCartClick} from '../../../../tracking/planPageTracking';
import { getUtmParams, combineParamsString, getUtmString } from '../../../../utils';


import {formatDate} from '../../../../utils'
interface Plans {
  'product_name':string,
  'sku':string,
  'current_price':number,
  'original_price':number,
  'from_price':number,
  'to_price':number,
  'duration':string,
}

interface Props {
  brands:string[],
  ac_types?:string[],
  ac_capacities?:{value:string, text:string}[],
  wp_types?:string[],
  category:string|null,
  category_id:string|number,
  router:any,
  plans: {
    type:string,
    details: {
      acType?:string,
      acCapacity?:string,
      brand?:string,
      plan:Plans,
    }[]
  },
  cart: any,
  addToCart: (data:any) => void,
  toggleSpecificFeatures: (data:any) => void,
  setCurrentStep: (data:any) => void,
  setAllPlans: (data:any) => void,
  toggleCartAlert: (data:any) => void,
  toggleResetStep: (data:boolean) => void,
  toggleRedirection: () => void,
  setCrossSellPlans: (data:any) => void,
  toggleLoading: (toggle:any) => void,
}

interface State {
  current_step:number,
  brand:string | null,
  wp_type:string | null,
  selected_plan: any,
  plans: any[],
  error: boolean,
  planAlertOpen:boolean,
  loading:boolean,
  tracking_params:any,
}

class WpPlanSelection extends React.Component<Props, State> {
  state:State = {
    current_step: 1,
    brand: null,
    wp_type: 'RO Water Purifier',
    plans: [],
    selected_plan: null,
    error: false,
    planAlertOpen: false,
    loading:false,
    tracking_params:{}
  }

  componentDidMount = () => {
    this.setState({current_step:1})
    this.props.toggleRedirection()
    this.props.toggleSpecificFeatures('ro')
    const path = this.props.router.asPath
    if(path.split('?').length > 1) {
      this.prefillIfExists(path)
    }
  }

  componentDidUpdate = () => {
    if(this.props.cart.resetStep) {
      this.props.toggleResetStep(false)
    }
    if(this.props.cart.redirectToCart) {
      this.props.toggleRedirection()
      this.props.router.push(combineParamsString(`/cart/${this.props.cart.token_id}`, getUtmString()))
    }
  }

  componentWillUnmount = () => {
    this.props.toggleSpecificFeatures(undefined)
    this.props.setCurrentStep(1)
    this.props.setAllPlans([])
  }

  prefillIfExists = (path:string) => {
    let {urlParams} = getParamsObjectFromString(path)
    let wp_type = ''
    let wp_brand = ''
    if(urlParams['device'] !== undefined) {
      let device = urlParams['device']
      while (device.includes('-')) {
        device = device.replace('-', ' ');
      }
      wp_type = device
    }

    if(urlParams['brand'] !== undefined) {
      let brand = urlParams['brand']
      while (brand.includes('-')) {
        brand = brand.replace('-', ' ')
      }
      wp_brand = brand
    }

    this.setState({
      wp_type:wp_type === '' ? 'RO Water Purifier' : wp_type,
      brand:wp_brand === '' ? null : wp_brand,
    }, () => {
      this.attemptProgressStep(urlParams)
    })
  }

  attemptProgressStep = (splitParams:{[index:string]:string}) => {
    if(splitParams['view-plans'] !== undefined) {
      let viewPlans = splitParams['view-plans']
      if(viewPlans === 'true') {
        this.progressStep()
      }
    }
  }

  selectBrand = (value:any) => {
    this.setState({
      brand: value.value
    })
    selectWpBrand("AMC", "Water Purifier") // GTM TRIGGER - select Wp Brand
  }

  selectWpType = (value:any) => {
    this.setState({
      wp_type:`${value} Water Purifier`
    })
    selectWpTypeTrigger("AMC", "Water Purifier") // GTM TRIGGER - select Wp Type
    this.props.toggleSpecificFeatures(value.toLowerCase())
  }

  changeSelectedPlan = (plan:Plans, position?:number) => {
    this.setState({selected_plan:plan})
    selectPlanClick("AMC", "Water Purifier") // GTM TRIGGER - select Plan Click
    viewItem({...plan, brand:this.state.brand}, 'Plan Page', position) // GTM TRIGGER - product Click
    return false;
  }

  progressStep = () => {
    // viewPlanClick("AMC", "Water Purifier") // GTM TRIGGER - view Plan Click
    this.setState({loading:true})
    if(this.state.brand !== null && this.state.wp_type !== null) {
      GetProducts(
        {
          application:'website',
          channel_name:'website',
          category:`${this.state.wp_type}`,
          product_group:'amc',
        }
      )
        .then((res:any) => {
          this.setState({loading:false})
          if(res.data) {
            if(res.data.products.length < 1) {
              this.setState({planAlertOpen:true})
            } else {
              this.setState({plans: res.data.products, current_step:2, error:false, selected_plan:res.data.products[0]})
              this.props.setCurrentStep(2)
              this.props.setAllPlans(res.data.products)
              // this.props.router.push(appendQueryParams(this.props.router.asPath, {device:this.state.wp_type, brand:this.state.brand}, this.state.current_step === 2), undefined, {shallow:true})
              let progressUrl = appendQueryParams(this.props.router.asPath, {device:this.state.wp_type, brand:this.state.brand}, true);
              window.history.replaceState(
                {
                  ...window.history.state,
                  as:progressUrl,
                  url:progressUrl,
                },
                '',
                progressUrl
              )
            }
          } else {
            //('No Plans Found');
          }
        })
        .catch((err) => {
          console.log(err)
          this.setState({loading:false})
          this.setState({error:true})
        });
    }
    return false;
  }

  addToCart = () => {
    addToCartClick("AMC", "Water Purifier") // GTM TRIGGER - add To Cart Click
    if(this.state.selected_plan) {
      let defaultStartDate = new Date;
      defaultStartDate.setDate(defaultStartDate.getDate() + 1)
      const data = {
        type: 'amc',
        token_id:this.props.cart.token_id,
        channel_name: 'website',
        item: {
          sku: this.state.selected_plan.sku,
          quantity: 1,
          device_detail: {
            brand:this.state.brand
          },
          preferred_plan_start_date: formatDate(defaultStartDate)
        },
      }
      this.props.setCrossSellPlans(null)
      data['tracking_params'] = getUtmParams()
      this.props.toggleLoading('addToCartAmcWP')
      this.props.addToCart(data)
    }
    return false;
  }

  editPlan = () => {
    this.setState({current_step: 1})
    this.props.router.push(appendQueryParams(this.props.router.asPath, {device:this.state.wp_type, brand:this.state.brand}, false), undefined, {shallow:true})
    return false;
  }


  render() {
    return (
      <React.Fragment>
        {this.state.current_step === 1 ?
          <DeviceDetails
            wpTypes={this.props.wp_types}
            brands={this.props.brands || []}
            selectBrand={this.selectBrand}
            selectedBrand={{text:this.state.brand, value:this.state.brand, key:this.state.brand}}
            selectWpType={this.selectWpType}
            selectedWpType={this.state.wp_type}
            progressStep={this.progressStep}
            router={this.props.router}
            button_enabled={this.state.brand !== null && this.state.wp_type !== null}
            loading={this.state.loading}
          />
          :
          <PlanDetails
            wpType={this.state.wp_type}
            brand={this.state.brand}
            plans={this.state.plans}
            changeSelectedPlan={this.changeSelectedPlan}
            addToCart={this.addToCart}
            editPlan={this.editPlan}
            loading={this.props.cart.loading==='addToCartAmcWP'}
          />
        }
        <UIModal isOpen={this.state.planAlertOpen} onClick={() => {this.setState({planAlertOpen:!this.state.planAlertOpen})}}>
          <PlanAlert onClick={() => {this.setState({planAlertOpen:!this.state.planAlertOpen})}} />
        </UIModal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state:any) => {
  return {
    cart:state.cart,
    plan:state.plans,
  }
}

const mapDispatchToProps = (dispatch:any) => {
  return {
    addToCart: (data:any) => dispatch(addToCart(data)),
    toggleSpecificFeatures: (data:string | undefined) => dispatch(toggleSpecificFeatures(data)),
    setCurrentStep: (data:any) => dispatch(setCurrentStep(data)),
    setAllPlans: (data:any) => dispatch(setAllPlans(data)),
    toggleCartAlert: (data:any) => dispatch(toggleCartAlert(data)),
    toggleResetStep: (data:boolean) => dispatch(toggleResetStep(data)),
    toggleRedirection: () => dispatch(toggleRedirection()),
    setCrossSellPlans: (data:any) => dispatch(setCrossSellPlans(data)),
    toggleLoading: (toggle:string) => dispatch(toggleLoading(toggle))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WpPlanSelection);
