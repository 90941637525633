import React from 'react';
import {PLAN_SELECTION_TYPE} from './config'
import UIModal from '../common/ui-components/surface/UIModal';
import CartAlert from '../cart/CartAlert';
import { toggleLoading,toggleCartAlert, removeItem } from '../../redux/actions/cartActions';
import { useRouter } from "next/router";
import { connect } from 'react-redux';
import CityChangePopUp from './../common/CityChangePopUp'
import { setCityChangePopUpAction } from './../../redux/actions/genericActions';
import { isAmcInstaCart } from '../../utils/cart';
import CITIES_SLUG from '../../city_constants';
import { getSelectedCityFromCookie } from '../../utils/cities';
import { cityChangePopupClose } from '../../tracking/segment/planPage';

interface HowItWorks {
  title: string,
  features: {
    image: string,
    subtitle: string,
    text: string
  }[]
}

interface Banner {
  title: string,
  subtitle: string,
  cta_text: string
}

interface PlanSelectionFeatures {
  title: string,
  benefits: {
    priority: number,
    text: string
  }[]
}

interface Features {
  how_it_works: HowItWorks,
  banner: Banner,
  plan_selection: PlanSelectionFeatures,
}

interface Props {
  productType: string,
  faqs: {
    title: string,
    answer: string,
    links: string,
    is_blog: boolean,
  }[],
  testimonials: any[],
  features: Features,
  brands?: string[],
  ac_types?: string[],
  wp_types?: string[]
  ac_capacities?: { value: string, text: string }[],
  router: any,
  plans: {
    [index: string] :
        {
            sku: string,
            plan: string,
            unit_price: string,
            amount: boolean,
            title_description: string,
            description_list: any,
            icons_url: string,
            category: string ,
            category_id:string|number,
            category_type: string,
            category_title: string,
            category_type_title: string,
            product_priority: number,
            location_discount: number
        }[]
    },
    testimonial: any[],
    compare_plan: {
      contents: any[],
      styles: string
    },
    blogs: any[],
    bannerImageData: any,
    cross_sell: any[],
    category:string,
    category_id:string|number,
    cart:any,
    toggleCartAlert: (data:any) => void,
    toggleHeaderSelection: (data:any) => void,
    toggleLoading: (toggle:any) => void,
    setCityChangePopUpAction: (data:any) => void,
    option:any,
    setOption:any,
    action:any,
    products:{cat_title:string, cat_slug:string, cat_type_slug:string, cat_type_title:string, group_name:string, group_slug:string, is_category_based:boolean, order:number}[],
    slug:any,
    insta_plans:any,
    template:any,
    genericReducer:any,
    slug_mapping:any
  }

const getComponent = (props: Props,) => {
  const router = useRouter();
  return PLAN_SELECTION_TYPE?.[props?.template]?.({...props, key:router.asPath, router:router})
}

const PlanBundle = (props: Props) => {
  const router = useRouter();
  const changeCity = props.cart.quantity && isAmcInstaCart(props.cart) && props.genericReducer.cityChangePopup;

  const close = () => {
    let cookie_city = getSelectedCityFromCookie()
    if( props.slug_mapping && cookie_city != CITIES_SLUG[props.slug_mapping?.city].city){
      router.push("/")
    }
    cityChangePopupClose({'source':'plan page','value':'Not Changed','current_city':cookie_city,'updated_city':''})
    props.setCityChangePopUpAction({'state':false})
  }
  return (
    <div>
      {getComponent(props)}
    
        <UIModal isOpen={!!props.cart.cartAlert && props.cart.cartAlertType=="cartAdd"} onClick={() => {props.toggleCartAlert({toggle:false, toggleType:undefined, data:undefined}),props.toggleLoading('')}}>
          <CartAlert onClick={() => {props.toggleCartAlert({toggle:false, data:undefined}),props.toggleLoading('')}}/>
        </UIModal>
        <UIModal isOpen={changeCity} onClick={close} onOverlayClick={()=>{close()}}>
          <CityChangePopUp router={router} slug_mapping={props.slug_mapping} source='plan page'/>
        </UIModal>
    </div>
  )
}

const mapStateToProps = (state:any) => {
  return {
    cart:state.cart,
    genericReducer: state.genericReducer
  }
}

const mapDispatchToProps = (dispatch:any) => {
  return {
    toggleCartAlert: (data:any) => dispatch(toggleCartAlert(data)),
    removeItem: (item:any, quantity: number, clear: boolean) => dispatch(removeItem(item, quantity, clear)),
    toggleLoading: (toggle:string) => dispatch(toggleLoading(toggle)),
    setCityChangePopUpAction: (data:any) => dispatch(setCityChangePopUpAction(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanBundle);
