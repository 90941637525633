import React, { Component } from 'react';
import Section from '../common/Section';
import styles from '../../styles/modules/components/device.module.scss';


class PlanDetailsSelection extends Component<any, any> {

  componentDidMount = () => {

  }

  render() {
    return (
      <Section className={styles.selection} id="plan-selection" key={this.props.router.asPath}>
        <div className={`${styles.selection__container} container`}>
          <div className={`${styles.selection__row} row`}>
            <div className={`${styles.selection__left} ${styles.selection__left__single} col-12 col-sm-12 col-md-12 col-lg-12`}>
              <this.props.PlanSelectionFields
                {...this.props}
              />
            </div>
          </div>
        </div>
      </Section>
    );
  }

}

export default PlanDetailsSelection;
