import React, { Component, Fragment } from 'react';

import styles from '../../../../styles/modules/components/homecare/selection.module.scss';
import HomecarePlans from './HomecarePlans';
import UIModal from '../../../common/ui-components/surface/UIModal';
import UITextField from '../../../common/ui-components/input/UITextField';
import Button from '../../../common/ui-components/input/Button';
import UIDropdown from '../../../common/ui-components/input/UIDropdown/UIDropdown';
import Config from '../../../../config';
import { CreateLead } from '../../../../api/cart';
import { requestCallbackClick, callbackFormName, callbackFormPhone, callbackFormCity, callbackFormCustomCity, callbackButtonClick } from '../../../../tracking/planPageTracking';
import { requestCallbackClicked, requestCallbackSubmitClicked } from '../../../../tracking/segment/planPage';


class HomeCarePlanSelection extends Component<any, any> {
  state:any = {
    customPlan: false,
    leadFormName: null,
    leadFormPhone: null,
    leadFormCity: null,
    leadFormCityCustom: null,
    leadFormSubmitted: false,
    leadResponseMessage:null,
    formErrors:{
      name: null,
      phone: null,
      city:null,
      cityCustom:null
    },
    mobileLayout: false,
  }
  cities = this.props.cities.map((city:any) => {
    return({
      text:city,
      value:city,
      key:city
    })
  })
  componentDidMount = () => {
    window.innerWidth > 992 ? this.setState({mobileLayout: false}) : this.setState({mobileLayout: true});
    window.addEventListener('resize',() => {
        if(window.innerWidth > 992) {
            this.state.mobileLayout ? this.setState({mobileLayout: !this.state.mobileLayout}) : '';
        }
        else {
            !this.state.mobileLayout ? this.setState({mobileLayout: !this.state.mobileLayout}) : '';
        }
    });
    this.cities.push({
      text: "Others",
      value: "Others",
      key: "Others",
    })
  }

  handleEnterPhone = (e:any) => {
    let phone = e.target.value.trim().replace(/\D/g, '');
    this.setState({leadFormPhone:phone})
  }
  handleClickCustom = () => {
    this.setState({customPlan:true});
    requestCallbackClicked({})
    requestCallbackClick('HomeCare', "HomeCare Premium")
  }
  submitLeadForm = () => {
    requestCallbackSubmitClicked({'phone':this.state.leadFormPhone, 'location':this.state.leadFormCity })
    if(this.state.leadFormName !== null && this.state.leadFormPhone !== null && this.state.leadFormCity !== null) {
      if(this.state.leadFormPhone.length == 10) {
        CreateLead({
          lead_type: 'homecare-premium',
          name:this.state.leadFormName,
          phone:this.state.leadFormPhone,
          city:this.state.leadFormCity,
          custom_city:this.state.leadFormCityCustom,
          application:'website',
          channel:'website',
          data_source:'plan-page:home-care-premium',
        })
        .then((res:any) => {
          this.setState({
            leadFormName: null,
            leadFormPhone: null,
            leadFormCity: null,
            leadFormCityCustom: null,
            leadFormSubmitted: true,
            leadResponseMessage: res.data.message,
          })
          callbackButtonClick('HomeCare', 'HomeCare Premium', 'Submit')
        })
        .catch((err:any) => {
          console.log(`An error occured while submitting data. ${err}`)
        })
      }
    }
  }

  enableFormSubmission = () => {
    if(this.state.leadFormName == null || this.state.leadFormPhone == null || this.state.leadFormCity == null) {
      return true
    } else if(this.state.leadFormName == '' || this.state.leadFormPhone == '') {
      return true
    } else {
      if(this.state.leadFormCity == "Others") {
        if(this.state.leadFormCityCustom == null || this.state.leadFormCityCustom == '') {
          return true
        }
      }
      if(this.state.leadFormPhone.length !== 10) {
        return true
      }
      return false
    }
    return false
  }

  render() {

    return (
      <Fragment>
        <div className={styles.homecare_selection}>
          <HomecarePlans
            enablePremium={Config.homecarePremuim}
            handleEnterPhone={this.handleEnterPhone}
            handleClickCustom={this.handleClickCustom}
            {...this.props}
          />
        </div>
        <UIModal
          isOpen={this.state.customPlan}
          onClick={
            () => this.setState({customPlan:false, leadFormName:null, leadFormPhone:null, leadFormCity:null, leadFormSubmitted: false, leadResponseMessage:null})
          }

        >
          <div className={`${styles.lead_form} container`}>
            {
              this.state.leadFormSubmitted ?
              <>
                <h5>{this.state.leadResponseMessage == 'There was an issue submitting your data. Please try again after some time' ? 'Sorry!' : 'Thank you!'}</h5>
                <div className={styles.lead_form__content}>
                <div className={styles.lead_form__inputs}>
                  <p>{this.state.leadResponseMessage}</p>
                </div>
                <div className={styles.lead_form__actions}>
                    <Button
                      text="Close"
                      variant="secondary"
                      className={styles.lead_form__dismiss}
                      onClick={() => {
                        this.setState({
                          customPlan:false,
                          leadFormName: null,
                          leadFormPhone: null,
                          leadFormCity: null,
                          leadFormSubmitted: false,
                          leadResponseMessage: null,
                        })
                      }}
                    />
              </div>
            </div>
            </>
            :
              <>
                <h5>Customer Details</h5>
                <div className={styles.lead_form__content}>
                <div className={styles.lead_form__inputs}>
                  <UITextField
                    className={styles.lead_form__name}
                    noLabel={true}
                    label="Name"
                    value={this.state.leadFormName || ''}
                    onChange={(e:any)=>{
                      this.setState({leadFormName:e.target.value})
                      if(e.target.value.length < 1) {
                        this.setState({
                          formErrors: {
                            ...this.state.formErrors,
                            name: 'Please enter a valid name'
                          }
                        })
                      } else {
                        this.setState({
                          formErrors: {
                            ...this.state.formErrors,
                            name:null
                          }
                        })
                      }
                    }}
                    onBlur={() => {
                      callbackFormName('HomeCare', 'HomeCare Premium')
                    }}
                    hasError={this.state.formErrors.name !== null}
                    propsError={this.state.formErrors.name}
                  />
                  <UITextField
                    className={styles.lead_form__phone}
                    type="number"
                    noLabel={true}
                    label="Phone Number"
                    value={this.state.leadFormPhone || ''}
                    onChange={(e:any)=>{
                      this.handleEnterPhone(e)
                    }}
                    onBlur={(e:any) => {
                      if(e.target.value.length !== 10) {
                        this.setState({
                          formErrors: {
                            ...this.state.formErrors,
                            phone: 'Please enter a valid 10 digit Phone Number'
                          }
                        })
                      } else {
                        this.setState({
                          formErrors: {
                            ...this.state.formErrors,
                            phone:null
                          }
                        })
                      }
                      callbackFormPhone('HomeCare', 'HomeCare Premium')
                    }}
                    hasError={this.state.formErrors.phone !== null}
                    propsError={this.state.formErrors.phone}
                  />
                  <UIDropdown
                    className={styles.lead_form__city}
                    label=""
                    selected_option={this.state.leadFormCity ?
                      {
                        text:this.state.leadFormCity, value:this.state.leadFormCity, key:this.state.leadFormCity
                      } : null
                    }
                    onChange={(e:any)=>{
                        this.setState({leadFormCity:e.value})
                        callbackFormCity('HomeCare', 'HomeCare Premium')
                    }}
                    options={this.cities}
                    placeholder="City"
                    position={this.state.mobileLayout ? "top": "bottom"}
                  />
                  {
                    this.state.leadFormCity=="Others" ?
                    <UITextField
                      noLabel={true}
                      className={styles.lead_form__phone}
                      label="City"
                      value={this.state.leadFormCityCustom || ''}
                      onChange={(e:any) => {
                        this.setState({
                          leadFormCityCustom:e.target.value
                        })
                        if(e.target.value.length < 1) {
                          this.setState({
                            formErrors: {
                              ...this.state.formErrors,
                              cityCustom: 'Please enter a valid city'
                            }
                          })
                        } else {
                          this.setState({
                            formErrors: {
                              ...this.state.formErrors,
                              cityCustom:null
                            }
                          })
                        }
                      }}
                      onBlur={() => {
                        callbackFormCustomCity('HomeCare', 'HomeCare Premium')
                      }}
                      hasError={this.state.formErrors.cityCustom !== null}
                      propsError={this.state.formErrors.cityCustom}
                    />
                    :
                    null
                  }
                </div>
                <div className={styles.lead_form__actions}>
                    <>
                      <Button
                        text="Cancel"
                        variant="secondary"
                        className={styles.lead_form__dismiss}
                        onClick={() => {
                          this.setState({
                            customPlan:false,
                            leadFormName: null,
                            leadFormPhone: null,
                            leadFormCity: null,
                            leadResponseMessage: null,
                          })
                          callbackButtonClick('HomeCare', 'HomeCare Premium', 'Cancel')
                        }}
                      />
                      <Button
                        text="Submit"
                        variant="primary"
                        className={styles.lead_form__submit}
                        disabled={this.enableFormSubmission()}
                        onClick={() => {this.submitLeadForm()}}
                      />
                  </>
                </div>
              </div>
              </>
            }
          </div>
        </UIModal>
      </Fragment>
    );
  }

}

export default HomeCarePlanSelection;
