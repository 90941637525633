import React, { Fragment } from "react";
import dynamic from "next/dynamic";
import { isMobile } from "react-device-detect";
import { connect } from 'react-redux';
import { setScrollElementData } from '../../redux/actions/planActions'
import styles from "../../styles/modules/common/features.module.scss";
import featuresStyles from "../../styles/modules/common/features.module.scss";
import { selectDeviceTypeClick } from '../../tracking/planPageTracking';
import { isDeviceType, getSkuSelectionInfo, arrangeCategories, getParamsObjectFromString } from "../../utils";
import { makePlans } from "../instarepair/utils";
import { showToast, toggleRedirection } from "../../redux/actions/cartActions";
import { getCookieCitySlugMap, redirectIfServiceable, setSelectedCityInCookie } from '../../utils/cities';
import { getPageViewData } from "../../utils/tracking";
import { productDetailScreenViewed } from "../../tracking/segment/planPage";
import { pageCallSegment } from "../../utils/tracking/segmentTracking";
import { GetSkuDisplayConfig } from "../../api/plan";
import { setSpecialCampaign } from "../../utils/cookies";
import { viewItemList } from "../../tracking/ecommerceTracking";

const LazyLoader = dynamic(()=> import("@/hoc/LazyLoader"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);


const Blog = dynamic(()=> import("../common/Blog"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const Faq = dynamic(()=> import("../common/Faq"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const CrossSell = dynamic(()=> import("../../components/cross-sell/CrossSell"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const FeatureImageBanner = dynamic(()=> import("../../components/common/FeatureImageBanner"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const Testimonial = dynamic(()=> import("./../common/Testimonials"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const CommonDeviceProblems = dynamic(()=> import("../common/CommonDeviceProblems"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const NotSureWhatLooking = dynamic(()=> import('../../components/not-sure/NotSureWhatLooking'),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const CityandBreadCrumb = dynamic(()=> import('../common/CItyandBreadCrumb'),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const BannerWithGraphic = dynamic(()=> import('../common/BannerWithGraphic'),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const SkuSelector = dynamic(()=> import('../instarepair/planselection/SkuSelector'),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const WhatDoYouGet = dynamic(()=> import('./../plan/AMCWaterPurifier/WhatDoYouGet'),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const ComparePlans = dynamic(()=> import('../common/ComparePlans'),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const Cities = dynamic(()=> import('../cities-brands'),
  { loading: () => <p>Loading ...</p>,ssr:true }
);
interface Props {
  productType: string,
  faqs: {
    title: string,
    answer: string,
    links: string,
    is_blog: boolean,
  }[],
  testimonials: any[],
  testimonial: any[],
  features: any,
  brands?:string[],
  ac_types?:string[],
  headings?:any,
  ac_capacities?:{value:string, text:string}[],
  router:any,
  toggleHeaderSelection: (data:any) => void,
  setScrollElementData: (data:any) => void,
  setHeaderData: (data:any) => void,
  setShowToast: (data: any) => void,
  plans:any,
  heading:string,
  category:string,
  category_id:string|number,
  compare_plan: {
    contents: any[],
    styles: string
  },
  blogs: any[],
  bannerImageData: any,
  cross_sell: any[],
  products:{cat_title:string, cat_slug:string, cat_type_slug:string, cat_type_title:string, group_name:string, group_slug:string, is_category_based:boolean, order:number}[],
  option:any,
  setOption:any,
  action:any,
  categories?:any,
  device:any,
  insta_plans:any,
  cart:any,
  planStates:any,
  sort_list:any,
  breadcrumb:[],
  slug_mapping?:any,
  testimonial_heading?:any,
  slug?:any,
  page_meta:any,
  product_group?:any,
  sku_display_config?:any
}


class MaintenanceAndServices extends React.Component<Props>  {
  state = {
    ...this.props,
    category: "",
    category_type_slug: '',
    planStates: {
      order_total: 0
    },
    items:{},
    cart_total: 0,
    actions: [],
    cart_id : "",
    total_items: 0,
    isFront: false,
    plans: [],
    categories: [],
    maxHeight:'auto',
    page_view:false,
    pine_labs:false,
    sku_display_config:{}
  }

  makeCategories = (plans:any) => {
    let cat:any = {}
    plans.forEach((plan:any) => {
      if(cat[plan.category] === undefined) {
        cat[plan.category] = {}
      }
    })

    return Object.keys(cat).sort()
  }

  getCategory = () => {
    if(this.state.categories.length > 0) {
      let category_type_slug = this.state.categories[0]
      this.setState({category:category_type_slug})
    }
  }

  setPageView = () => {
  }

  componentDidMount = () => {
    let pine_labs_campaign = getParamsObjectFromString(window.location.href)['urlParams']['osg_campaign'] === 'pinelabs'
    if (pine_labs_campaign) this.setState({pine_labs: pine_labs_campaign});
    var slug:any = this.props.router.query.slug
    let split:any = []
    slug.replace('-repair-service', '').split('-').forEach(i => {
      split.push(i.replace(i[0], i[0].toUpperCase()))
    })
    let categoryType = split.join(' ')
    categoryType = categoryType.slice(0, categoryType.length - 1)
    let categories:any = arrangeCategories(this.makeCategories(this.props.insta_plans || this.props.plans), this.props.device)
    this.setState({
      plans: this.props.insta_plans,
      category: this.props.insta_plans ? categories[0] : {},
      categories: categories
    })
    this.getCategory()
    if(!this.state.page_view){
      this.setState({ page_view: true })
    }
    let additional_details:any = getSkuSelectionInfo(this.props);
    if (additional_details.is_mobile_tablet) {
      this.setState({
        category: additional_details?.mobile_tablet_category_tabs[0]
      })
    }
    let params:any = getParamsObjectFromString(window.location.href)['urlParams'];
    if(params['cityreset']) setSelectedCityInCookie("");
    redirectIfServiceable(this.props.slug,this.props.router)

    if(!this.props.slug_mapping.is_generic){
      pageCallSegment("Product Detail - Screen Viewed")
      productDetailScreenViewed({'categories':this.props.categories.join(','),'product_group':this.props.product_group.join(','),...getPageViewData()})
    }
    if(this.props.slug_mapping.is_generic && !!!getCookieCitySlugMap(this.props.slug_mapping)){
      pageCallSegment("Product Detail - Screen Viewed")
      productDetailScreenViewed({'categories':this.props.categories.join(','),'product_group':this.props.product_group.join(','),...getPageViewData()})
    }
    // API call to fetch Config Data
    GetSkuDisplayConfig({
      application: 'website'
    }).then((res:any)=>{
        this.setState({sku_display_config: res.data ? res.data : {}})
        setSpecialCampaign(res.data ? res.data : {})
    })
    if(this.props.productType === "amc" ){
      viewItemList(this.props.insta_plans)
    }
  } 

  componentDidUpdate() {
    if(this.getItemCount() !== this.state.total_items){
      var plans = makePlans(this.props.cart.items,this.state.plans)
      this.setState({plans: plans,total_items: this.getItemCount()})
    }
    if(this.props.planStates.showScrollAfterElement !== 'why-choose') {
      this.props.setScrollElementData('why-choose')
    }
  }

  componentWillUnmount = () => {
    if(this.state.page_view){
      this.setState({ page_view: false })
    }
  }

  getCitiesHeading = () => {
    let additional_details:any = getSkuSelectionInfo(this.props);
    if (additional_details.is_mobile_tablet) {
      return "Mobile and Tablet InstaRepair"
    }
    return `${this.props.device} AMC and Services`
  }

  getItemCount = () => {
    let itemLength:any = 0
    if(this.props.cart.items && Object.keys(this.props.cart.items).length > 0 && this.props.cart.type === 'instarepair') {
      itemLength = 0
      Object.keys(this.props.cart.items).forEach((key) => {
        itemLength += this.props.cart.items[key].reduce((a, b) => ({quantity : a.quantity + b.quantity})).quantity
      })
    }
    return itemLength;
  }

  handleCategoryChange = (event) => {
    this.setState({ ...this.state, category: event.target.id })
    let category = event.target.id;
    if(event.target.id.includes('AC')){
      category = 'Air Conditioner';
    }else if(event.target.id.includes('Water Purifier')){
      category = 'Water Purifier';
    }
    selectDeviceTypeClick("AMCInsta", category);
  }

  render() {
      const props = this.props;
      const {isAC, isWP, isCH} = isDeviceType(this.props.device)
      let is_pine_labs_campaign = this.state.pine_labs
      let additional_details:any = getSkuSelectionInfo(this.props);

      let blog_title = '';
      if(isAC){
        blog_title = `Tips & Common Solutions to Keep Your AC Running`
      } else if (additional_details.is_mobile_tablet) {
        blog_title = "Handpicked Blogs on Mobile Phone and Tablet"
      } else {
        blog_title = `Handpicked Blogs on ${this.props.device}`
      }
      let commonDeviceProblems = this.props.features?.commonDeviceProblems ? this.props.features?.commonDeviceProblems : {};
      return (
        <Fragment>
          <CityandBreadCrumb slug_mapping={this.props.slug_mapping} device_name={this.props.device} categories={this.props.categories ? this.props.categories : ''} breadcrumb={this.props.breadcrumb?this.props.breadcrumb:[]} cart={this.props.cart} setShowToast={this.props.setShowToast}/>
          <BannerWithGraphic cta_button_title={this.props.bannerImageData ? this.props.bannerImageData.cta_text : ''} title={this.props.bannerImageData ? this.props.bannerImageData.title : ''} subtitle={this.props.bannerImageData ? this.props.bannerImageData.subtitle : ''} banner_img={this.props.bannerImageData ? this.props.bannerImageData.image : ''} journeyType="AMCInsta" categories={this.props.categories ? this.props.categories : ''} />
          <SkuSelector
            {...this.props}
            {...isDeviceType(this.props.device)}
            plans={this.state.plans}
            sku_display_config={this.state.sku_display_config}
            pineLabs={is_pine_labs_campaign}
            tabCategories={this.props.sort_list?.length !=0 ? this.props.sort_list :this.state.categories}
            selectedCategory={this.state.category}
            handleCategoryChange={this.handleCategoryChange}
            additional_details={getSkuSelectionInfo(props)}
             />
          <LazyLoader>   
          <div className='odd-even-start-with-gray'>
            { isCH && <FeatureImageBanner title={this.props.features.featuresImageBannerAC !== undefined ? this.props.features.featuresImageBannerAC.title : ''} withSubtitle={false} features={this.props.features.featuresImageBannerAC !== undefined ? this.props.features.featuresImageBannerAC.featuresOfPlan : ''} isGreyBackground={isMobile} elementID={'ac-required'}/> }
            { isCH && <FeatureImageBanner
              title={this.props.features?.stepsToProtect?.title}
              withSubtitle={false}
              features={this.props.features?.stepsToProtect?.steps}
              className={`${featuresStyles.grey__neutral}`}
              isGreyBackground={true}
              sectionClassName={`${featuresStyles.font__dark}`}
              isRoundShape={true}
              imageSizeClass={featuresStyles.amc__circle_image}/> }
            { isWP && <WhatDoYouGet /> }
            <FeatureImageBanner title={this.props.features.whyChooseOnsitego !== undefined ? this.props.features.whyChooseOnsitego.title : ''} withSubtitle={false} features={this.props.features.whyChooseOnsitego !== undefined ? this.props.features.whyChooseOnsitego.steps : ''} className={`${styles.grey__neutral}`} isGreyBackground={isMobile == false} elementID='why-choose' />
            {props.testimonials && props.testimonials.length > 0 &&<Testimonial heading={props.page_meta.testimonial_heading} testimonial={props.testimonials} category={this.props.category} router={props.router}/>}
            <CommonDeviceProblems problems={commonDeviceProblems.problems} title={commonDeviceProblems.title} description={commonDeviceProblems.description} page_url={this.props.slug} category={this.props.category}/>
            <Faq title="Frequently Asked Questions" questions={props.faqs} category={this.props.category} className="faq-primary" page_url={this.props.slug} pineLabs={is_pine_labs_campaign}/>
            {!is_pine_labs_campaign && (isAC || isCH) && <ComparePlans compare_plan={props.compare_plan}/> }
            <Cities headings={this.getCitiesHeading()} {...this.props} />
            {!is_pine_labs_campaign && <CrossSell list={props.cross_sell} router={props.router} category={this.props.category}/>}
            {!is_pine_labs_campaign && <Blog key="blog" blog={props.blogs} title={blog_title} category={this.props.category}/>}
            {!is_pine_labs_campaign && <NotSureWhatLooking products={this.props.products} option={this.props.option} setOption={this.props.setOption} action={(e) => this.props.action(e)}/>}
          </div>
          </LazyLoader>
        </Fragment>
      )
  }
}

const mapStateToProps = (state: any) => {
    return {
        planStates: state.instarepairplans,
        cart: state.cart
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        toggleRedirection: () => dispatch(toggleRedirection()),
        setScrollElementData: (data:any) => dispatch(setScrollElementData(data)),
        setShowToast: (data:any) =>dispatch(showToast(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceAndServices);
