import React, { Fragment} from 'react';
import Section from './Section';
import Button from './ui-components/input/Button';
import styles from '../../styles/modules/common/banner-graphic.module.scss';
import Image from '../../components/common/Image';
import { getStarted } from '../../tracking/planPageTracking';
import { ABExperimentConfig, showCustomPage } from '../../utils/abTesting';
import { getStartedClicked } from '../../tracking/segment/planPage';
import { useRouter } from 'next/router';
import { isEWPlan } from '../../utils';

interface Props {
    title: string,
    subtitle: string,
    cta_button_title?: string,
    banner_img?: any,
    onCtaClick?: (e?: React.MouseEvent) => {},
    className?: string,
    isCommon?: boolean | false,
    journeyType?: string,
    categories?: any,
    planType?: any,
    breadcrumb?: any,
    slug_mapping?:any,
    slug?: any
}

const BannerWithGraphic = (props: Props) => {
    const { title, subtitle, cta_button_title, banner_img, className, isCommon, journeyType, categories } = props;
    const router = useRouter()
    const hide_getstarted = ABExperimentConfig('rate_us_ab',props.slug) && showCustomPage('rate_us_ab')

    return (
        <Fragment>
            <Section className={`${styles.banner} ${className ? className : ''}`.trim()} id="cta-banner" hasPadding={false}>
                <div className={`app-section__container container`} style={{"position":"relative"}}>
                    <div className={`${styles._row} row`}>
                        <div className={`${styles._graphic} col-12 col-sm-12 col-md-12 ${isCommon ? 'col-lg-6' : 'col-lg-6'}`}>
                            {router.query.slug == 'mobile-phones-extended-warranty-plans' ?
                                <figure>
                                <Image src={banner_img || ''} alt={title} width="600" height="600"/>
                            </figure>
                            :
                            <figure>
                                <Image src={banner_img || ''} alt={title} width="730" height="516"/>
                            </figure>
                            }
                        </div>
                        <div className={`${styles._typhograpy} col-12 col-sm-12 col-md-12 ${isCommon ? 'col-lg-6' : 'col-lg-6'}`}>
                        <h1 className={styles._h1}>{title}</h1>
                            {!hide_getstarted && <p>{subtitle}</p>}
                            {!hide_getstarted && <div className={`${styles._banner_action}  ${isEWPlan(router.query.slug) && styles._hide_get_started}`}>
                                {
                                    cta_button_title &&
                                    <Button text={cta_button_title} onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector("#plan-selection")?.scrollIntoView({
                                            behavior: 'smooth', block: 'nearest',
                                        })
                                        getStarted(journeyType, categories) // GTM TRIGGER - get Started
                                        getStartedClicked({'page_link':router.asPath})
                                    }} variant="primary" />
                                }
                            </div>}
                        </div>
                    </div>
                </div>
            </Section>
        </Fragment>
    );
}

export default BannerWithGraphic;