import React, { Fragment } from "react";
import BannerWithGraphic from '../common/BannerWithGraphic';
import DeviceDetailsSelection from '../../components/user-interaction/DeviceDetailsSelection';
import PlanSelectionFields from './PlanSelectionFields';
import WhyShouldYouGet from "./../plan/AMCWaterPurifier/WhyShouldYouGet";
import WhatDoYouGet from "./../plan/AMCWaterPurifier/WhatDoYouGet";
import { setHeaderData } from '../../redux/actions/planActions';
import { setScrollElementData } from '../../redux/actions/planActions'
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import styles from "../../styles/modules/common/features.module.scss";
import dynamic from "next/dynamic";

const Blog = dynamic(()=> import("../common/Blog"),
{loading: () => <p>Loading ...</p>,ssr:true }
);

const Faq = dynamic(()=> import("../common/Faq"),
{loading: () => <p>Loading ...</p>,ssr:true}
);

const Testimonial = dynamic(()=> import("../common/Testimonials"),
{loading: () => <p>Loading ...</p>,ssr:true }
);



const FeatureImageBanner = dynamic(()=> import("../common/FeatureImageBanner"),
{loading: () => <p>Loading ...</p>,ssr:true }
);

const CrossSell = dynamic(()=> import("../../components/cross-sell/CrossSell"),
{loading: () => <p>Loading ...</p>,ssr:true }
);

const NotSureWhatLooking = dynamic(()=> import('../../components/not-sure/NotSureWhatLooking'),
{loading: () => <p>Loading ...</p>,ssr:true }
);






interface Props {
  productType: string,
  faqs: {
    title: string,
    answer: string,
    links: string,
    is_blog: boolean,
  }[],
  testimonials: any[],
  testimonial: any[],
  features: any,
  brands?: string[],
  ac_types?: string[],
  wp_types?: string[],
  ac_capacities?: { value: string, text: string }[],
  router: any,
  setHeaderData: (data: any) => void,
  setScrollElementData: (data: any) => void,
  plans: any,
  heading: string,
  blogs: any[],
  category_id: any,
  bannerImageData: any,
  cross_sell: any[],
  products:{cat_title:string, cat_slug:string, cat_type_slug:string, cat_type_title:string, group_name:string, group_slug:string, is_category_based:boolean, order:number}[],
  option:any,
  setOption:any,
  action:any,
  categories?:any
}

const sendHeaderData = (props: any) => {
  let headerData = {}
  headerData['productType'] = props.productType
  headerData['brands'] = props.brands
  headerData['wp_types'] = props.wp_types.map(type => `${type} Water Purifier`)

  props.setHeaderData(headerData)
}


const WaterPurifierAmc = (props: Props) => {
  const [headerDataSent, setDataSent] = React.useState<boolean>(false)
  const [loaded, setLoaded] = React.useState<boolean>(false)

  const handleScroll = () => {
    setLoaded(true)
    window.removeEventListener('scroll', handleScroll);
  }

  React.useEffect(() => {
    if (!headerDataSent) {
      sendHeaderData(props);
      setDataSent(true);
    }
    window.addEventListener('scroll', handleScroll);
      // trackPage("Category Page",{})
  },[])



  React.useEffect(()=>{
    if(props.plans.showScrollAfterElement !== 'wp-required') {
      props.setScrollElementData('wp-required')
    }
  },[props.plans.showScrollAfterElement])
  
  return (
    <Fragment>
      <BannerWithGraphic title={props.bannerImageData ? props.bannerImageData.title: ''} subtitle={props.bannerImageData ? props.bannerImageData.subtitle: ''} cta_button_title={props.bannerImageData? props.bannerImageData.cta_text: ''} onCtaClick={() => false} banner_img={props.bannerImageData? props.bannerImageData.image : ''} journeyType="AMC" categories={['Water Purifier']}/>
      <DeviceDetailsSelection
        title={props.features.plan_selection !== undefined ? props.features.plan_selection.title : ''}
        benefits={props.features.plan_selection.benefits || []}
        specificBenefits={props.features.plan_selection.plan_specific_benefits || []}
        PlanSelectionFields={PlanSelectionFields}
        productType={props.productType}
        brands={props.brands || []}
        wp_types={props.wp_types}
        router={props.router}
        category_id={props.category_id}
      />

    <div className='odd-even-start-with-white'>

      <FeatureImageBanner title={props.features.featuresImageBannerWP !== undefined ? props.features.featuresImageBannerWP.title : ''} withSubtitle={false} features={props.features.featuresImageBannerWP !== undefined ? props.features.featuresImageBannerWP.featuresOfPlan : ''} isGreyBackground={isMobile == true} elementID={'wp-required'}/>
      {/* add plan compare component here*/}
      {loaded && <FeatureImageBanner title={props.features.stepsToProtectWP !== undefined ? props.features.stepsToProtectWP.title : ''} withSubtitle={false} features={props.features.stepsToProtectWP !== undefined ? props.features.stepsToProtectWP.steps : ''}  className={`${styles.grey} ${styles.how_to_enjoy_ac}`}isGreyBackground={isMobile == true}  isRoundShape={true} imageSizeClass={styles.amc__circle_image} />}
      {loaded && <WhyShouldYouGet /> }
      {loaded && <WhatDoYouGet />}
      {loaded && <FeatureImageBanner title={props.features.ourPromiseBannerWP !== undefined ? props.features.ourPromiseBannerWP.title : ''} withSubtitle={false} features={props.features.ourPromiseBannerWP !== undefined ? props.features.ourPromiseBannerWP.steps : ''} className={`${styles.grey__neutral}`} isGreyBackground={isMobile == false} />}
      {props.testimonials && props.testimonials.length !== 0 && <Testimonial testimonial={props.testimonials} />}
      {props.blogs && props.blogs.length !== 0 && <Blog key="wp-blog" blog={props.blogs?props.blogs:[]} title="A Healthy Water Purifier For A Healthy Home"/>}
      {props.faqs && props.faqs.length !== 0 ? <Faq title="Frequently Asked Questions" questions={props.faqs} className="faq-primary" /> : null}
      {/* <Cities headings={"AMC"} {...props} /> */}
      {props.cross_sell.length !== 0 ? (<CrossSell list={props.cross_sell} router={props.router} />) : ''}
      {props.products ? <NotSureWhatLooking products={props.products} option={props.option} setOption={props.setOption} action={(e) => props.action(e)}/> : null}

    </div>
    </Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    plans: state.plans
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHeaderData: (data: any) => dispatch(setHeaderData(data)),
    setScrollElementData: (data:any) => dispatch(setScrollElementData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaterPurifierAmc);
