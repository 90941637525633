import React, { Fragment } from "react";
import BannerWithGraphic from "../common/BannerWithGraphic";
import DeviceDetailsSelection from "../../components/user-interaction/DeviceDetailsSelection";
import PlanSelectionFields from "./PlanSelectionFields";
import dynamic from "next/dynamic";
import ComparePlans from "../common/ComparePlans";
import Cities from "../cities-brands";
import { isMobile } from "react-device-detect";
import { connect } from 'react-redux';
import { setHeaderData } from '../../redux/actions/planActions';
import { setScrollElementData } from '../../redux/actions/planActions'
import styles from "../../styles/modules/common/features.module.scss";

const LazyLoader = dynamic(()=> import("@/hoc/LazyLoader"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const Blog = dynamic(()=> import("../common/Blog"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const Faq = dynamic(()=> import("../common/Faq"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const CrossSell = dynamic(()=> import("../../components/cross-sell/CrossSell"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const FeatureImageBanner = dynamic(()=> import("../../components/common/FeatureImageBanner"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const Testimonial = dynamic(()=> import("./../common/Testimonials"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const NotSureWhatLooking = dynamic(()=> import('../../components/not-sure/NotSureWhatLooking'),
{loading: () => <p>Loading ...</p>,ssr:true }
);

interface Props {
  productType: string,
  faqs: {
    title: string,
    answer: string,
    links: string,
    is_blog: boolean,
  }[],
  testimonials: any[],
  testimonial: any[],
  features: any,
  brands?:string[],
  ac_types?:string[],
  ac_capacities?:{value:string, text:string}[],
  router:any,
  toggleHeaderSelection: (data:any) => void,
  setScrollElementData: (data:any) => void,
  setHeaderData: (data:any) => void,
  plans:any,
  heading:string,
  category:string,
  category_id:string|number,
  compare_plan: {
    contents: any[],
    styles: string
  },
  blogs: any[],
  bannerImageData: any,
  cross_sell: any[],
  products:{cat_title:string, cat_slug:string, cat_type_slug:string, cat_type_title:string, group_name:string, group_slug:string, is_category_based:boolean, order:number}[],
  option:any,
  setOption:any,
  action:any,
  categories?:any
  
}

const sendHeaderData = (props: any) => {
  let headerData = {}
  headerData['productType'] = props.productType
  headerData['brands'] = props.brands
  headerData['ac_types'] = props.ac_types
  headerData['ac_capacities'] = props.ac_capacities

  props.setHeaderData(headerData)
}

class AirConditionerAmc extends React.Component<Props>  {
  state = {
    headerDataSent: false,
    loaded: false
  }
  setDataSent = (b: boolean) => {
    this.setState({
      headerDataSent: b
    });
  }
  componentDidMount = () => {
    const { headerDataSent } = this.state;
    if (!headerDataSent) {
      sendHeaderData(this.props);
      this.setDataSent(true);
    }
    window.addEventListener('scroll', this.handleScroll);
    // tracking page
  }
  componentDidUpdate = () => {
    if(this.props.plans.showScrollAfterElement !== 'ac-required') {
      this.props.setScrollElementData('ac-required')
    }
  }

  handleScroll = () => {
    this.setState({loaded: true})
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
  const props = this.props;

    return (
      <Fragment>
        <BannerWithGraphic title={this.props.bannerImageData ? this.props.bannerImageData.title: ''} subtitle={this.props.bannerImageData ? this.props.bannerImageData.subtitle: ''} cta_button_title={this.props.bannerImageData? this.props.bannerImageData.cta_text: ''} onCtaClick={() => false} banner_img={this.props.bannerImageData? this.props.bannerImageData.image : ''} journeyType="AMC" categories={['Air Conditioner']}/>
        <DeviceDetailsSelection
          title={props.features.plan_selection !== undefined ? props.features.plan_selection.title : ''}
          benefits={props.features.plan_selection.benefits || []}
          specificBenefits={props.features.plan_selection.plan_specific_benefits || []}
          PlanSelectionFields={PlanSelectionFields}
          productType={props.productType} brands={props.brands || []}
          ac_types={props.ac_types}
          ac_capacities={props.ac_capacities}
          router={props.router}
          category_id={props.category_id}
        />
      <LazyLoader>
      <div className='odd-even-start-with-white'>
        <FeatureImageBanner title={props.features.featuresImageBannerAC !== undefined ? props.features.featuresImageBannerAC.title : ''} withSubtitle={false} features={props.features.featuresImageBannerAC !== undefined ? props.features.featuresImageBannerAC.featuresOfPlan : ''} isGreyBackground={isMobile == true} elementID={'ac-required'}/>
        {props.compare_plan && <ComparePlans compare_plan={props.compare_plan}/>}
        {this.state.loaded && <FeatureImageBanner title={props.features.stepsToProtectAC !== undefined ? props.features.stepsToProtectAC.title : ''} withSubtitle={false} features={props.features.stepsToProtectAC !== undefined ? props.features.stepsToProtectAC.steps : ''}  className={`${styles.grey} ${styles.how_to_enjoy_ac}`}isGreyBackground={isMobile == true}  isRoundShape={true} imageSizeClass={styles.amc__circle_image} />}
        {this.state.loaded && <FeatureImageBanner title={props.features.ourPromiseBannerAC !== undefined ? props.features.ourPromiseBannerAC.title : ''} withSubtitle={false} features={props.features.ourPromiseBannerAC !== undefined ? props.features.ourPromiseBannerAC.steps : ''} className={`${styles.grey__neutral}`} isGreyBackground={isMobile == false} />}
        {props.testimonials && props.testimonials.length > 0 && <Testimonial testimonial={props.testimonials} />}
        {props.blogs && props.blogs.length > 0 && <Blog key="wp-blog" blog={props.blogs?props.blogs:[]} title="Tips & Common Solutions to Keep Your AC Running"/>}
        {props.faqs && props.faqs.length !== 0 ? <Faq title="Frequently Asked Questions" questions={props.faqs} className="faq-primary" /> : null}
        <Cities headings={"AMC"} {...props} />
        {props.cross_sell.length !== 0 ? (<CrossSell list={props.cross_sell} router={props.router} />) : ''}
        {props.products ? <NotSureWhatLooking products={props.products} option={props.option} setOption={props.setOption} action={(e) => props.action(e)}/> : null}
      </div>
      </LazyLoader>
      </Fragment>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    plans: state.plans
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHeaderData: (data: any) => dispatch(setHeaderData(data)),
    setScrollElementData: (data: any) => dispatch(setScrollElementData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AirConditionerAmc);
