import {PLAN_SELECTION_TYPE} from './config'

interface Props {
  productType:string,
  brands: string[],
  ac_types?:string[],
  wp_types?:string[],
  ac_capacities?:{value:string, text:string}[],
  router:any,
  category:string|null,
  category_id:string|number,
  device?:string,
}

const getComponent = (props:any) => {
  if(props.router.asPath.includes('air-conditioners-amc')) {
    return PLAN_SELECTION_TYPE['amc']({brands:props.brands || [], router:props.router, ac_types:props.ac_types,ac_capacities:props.ac_capacities, category_id:props.category_id})
  }
  if(props.router.asPath.includes('water-purifiers-amc')) {
    return PLAN_SELECTION_TYPE['wp']({brands:props.brands || [], router:props.router, wp_types:props.wp_types, category_id:props.category_id})
  }
  if(props.router.asPath.includes('home-repair-maintenance')) {
    return PLAN_SELECTION_TYPE['hc']({...props})
  }
  return PLAN_SELECTION_TYPE['ew']({brands:props.brands || [], router:props.router, category:props.category, category_id:props.category_id, productType:props.productType,device:props.device})
}

const PlanSelectionFields = (props:Props) => {
  return getComponent(props)
}

export default PlanSelectionFields;
