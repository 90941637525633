import React, { useState } from 'react'
import Section from '../../common/Section';
import styles from '../../../styles/modules/components/AMCWaterPurifier/what-do-you-get.module.scss'
import Image from '../../common/Image';

const WhatDoYouGet = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false)
    const props = {
        title : "Repure Kit",
        subtitle: "Best in class water purifier filters and membranes to ensure clean and healthy water",
    }
    const {title, subtitle} = props;
    React.useEffect(() =>{
        if(window.innerWidth > 992) {
            isMobile ? setIsMobile(false) : null;
        }else {
          !isMobile ? setIsMobile(true) : null;
      }
    },[isMobile])
    
    return (
        <Section hasPadding={true}>
                <div className={`container`}>
                        <div className={`${styles._typograpy}`}>
                            <h4>{title}</h4>
                            <h5>{subtitle}</h5>
                        </div>
                        <div className={`row ${styles._row}`}>
                            <div className={`${styles.items} col-12 col-md-6`}>
                                <div className="row">
                                    <div className={`${styles.image} col-6 col-md-12`}>
                                        <Image  src={"/static/images/AMCWaterPurifier/ro.webp"} alt={"placeholder"} width="730" height="516" /> 
                                    </div>
                                    <div className={`${styles.text_content} col-6 col-md-12`}>
                                        <div className={`${styles.text_heading}`}>
                                            RO REPURE KIT
                                        </div>
                                        <div className={`${styles.text_subcontent}`}>
                                            Contains a Dupont FilmTec RO Membrane - World’s most trusted RO membrane, imported from the USA along with Sediment Filter, Pre-Carbon Filter & Post-Carbon Filter
                                        </div>
                                    </div>
                                </div>     
                            </div>
                            <div className={`${styles.items} col-12 col-md-6`}>
                                <div className="row">
                                    <div className={`${styles.image} col-6 col-md-12`}>
                                    <Image  src={"/static/images/AMCWaterPurifier/uv.webp"} alt={"placeholder"} width="730" height="516" /> 
                                    </div>
                                    <div className={`${styles.text_content} col-6 col-md-12`}>
                                        <div className={`${styles.text_heading}`}>
                                            UV REPURE KIT
                                        </div>
                                        <div className={`${styles.text_subcontent}`}>
                                            Contains Sediment Filter & Pre-Carbon Filter
                                        </div>
                                    </div>
                                </div>     
                            </div>
                              
                        
                        </div>  
                </div>
             
        </Section>
    )
}

export default WhatDoYouGet ;