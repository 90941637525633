import React, { Fragment } from "react";
import dynamic from "next/dynamic";
import BannerWithGraphic from '../common/BannerWithGraphic';
import DeviceDetailsSelection from '../../components/user-interaction/DeviceDetailsSelection';
import PlanSelectionFields from './PlanSelectionFields';
import featureStyles from '../../styles/modules/common/features.module.scss';
import { setHeaderData } from '../../redux/actions/planActions';
import { connect } from 'react-redux';
import {getDeviceFromSlug} from '../../utils';
import ComparePlans from "../common/ComparePlans";
import CityandBreadCrumb from "../common/CItyandBreadCrumb";
import { getPageViewData, getProtectionPlanName } from "../../utils/tracking";
import { productDetailScreenViewed } from "../../tracking/segment/planPage";
import { pageCallSegment } from "../../utils/tracking/segmentTracking";

const LazyLoader = dynamic(()=> import("@/hoc/LazyLoader"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const Blog = dynamic(()=> import("../common/Blog"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const Faq = dynamic(()=> import("../common/Faq"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const CrossSell = dynamic(()=> import("../../components/cross-sell/CrossSell"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const FeatureImageBanner = dynamic(()=> import("../../components/common/FeatureImageBanner"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const FeatureTwoRowComponent = dynamic(()=> import("../../components/common/FeatureTwoRowComponent"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const Testimonial = dynamic(()=> import("./../common/Testimonials"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const CoverageDetails = dynamic(()=> import("../../components/coverage-details/CoverageDetails"),
  {loading: () => <p>Loading ...</p>,ssr:true }
);

const NotSureWhatLooking = dynamic(()=> import('../../components/not-sure/NotSureWhatLooking'),
{loading: () => <p>Loading ...</p>,ssr:true }
);

const sendHeaderData = (props: any) => {
  let headerData = {}
  headerData['productType'] = props.productType
  headerData['brands'] = props.brands
  headerData['category'] = getDeviceFromSlug(props.category).slice(0, getDeviceFromSlug(props.category).length-1)
  headerData['category_id'] = props.category_id

  props.setHeaderData(headerData)
}
const comparePlansExcept = ["mobile-phones-extended-warranty-plans","tablets-extended-warranty-plans"]

class ProtectionPlans extends React.Component <any, any> {
  state = {
    headerDataSent: false,
    loaded: false
  }
  setDataSent = (b: boolean) => {
    this.setState({
      headerDataSent: b
    });
  }
  componentDidMount = () => {
    const { headerDataSent } = this.state;
    if (!headerDataSent) {
      sendHeaderData(this.props);
      this.setDataSent(true);
    }
    window.addEventListener('scroll', this.handleScroll);
    pageCallSegment("Product Detail - Screen Viewed")
    productDetailScreenViewed({'categories':this.props.categories.join(','),'product_group':this.props.product_group.join(','),...getPageViewData(), 'product_name':getProtectionPlanName(this.props.productType, this.props.category)})
  }

  handleScroll = () => {
    this.setState({loaded: true})
    window.removeEventListener('scroll', this.handleScroll);
  }
  render() {
    const props = this.props;
    return (
      <Fragment>
        <CityandBreadCrumb slug_mapping={this.props.slug_mapping}  categories={this.props.categories ? this.props.categories : ''} breadcrumb={this.props.breadcrumb?this.props.breadcrumb:[]}/>
        <BannerWithGraphic title={props.bannerImageData ? props.bannerImageData.title: ''}  subtitle={props.bannerImageData ? props.bannerImageData.subtitle: ''} cta_button_title={props.bannerImageData? props.bannerImageData.cta_text: ''} onCtaClick={() => false} banner_img={props.bannerImageData? props.bannerImageData.image : ''} journeyType={props.plans.plan_specific_features ? props.plans.plan_specific_features.toUpperCase() : ''} categories={this.props.categories ? this.props.categories : '' } breadcrumb={this.props.breadcrumb?this.props.breadcrumb:[]} slug={this.props.slug}/>
        <DeviceDetailsSelection
          title={props.features.plan_selection.title || ''}
          benefits={props.features.plan_selection.benefits || []}
          specificBenefits={props.features.plan_selection.plan_specific_benefits || []}
          PlanSelectionFields={PlanSelectionFields}
          productType={props.productType}
          brands={props.brands || []}
          ac_types={props.ac_types}
          ac_capacities={props.ac_capacities}
          router={props.router}
          without_coverage={true}
          category={props.category}
          category_id={props.category_id}
          device={props.device}
        />
        <div className='odd-even-start-with-white'>
          {Object.keys(props.coverage_data).length !== 0 ? <CoverageDetails title={props.coverage_data.title} subtitle={props.coverage_data.subtitle} image={props.coverage_data.image} data={props.coverage_data.data} /> : null}
          {this.state.loaded && props.features.how_it_works && <FeatureImageBanner title={props.features.how_it_works !== undefined ? props.features.how_it_works.title : ''} withSubtitle={false} features={props.features.how_it_works !== undefined ? props.features.how_it_works.features : ''}  className={`${featureStyles.grey__neutral} ${featureStyles.how_to_enjoy_ac}`}isGreyBackground={false} isRoundShape={true} imageSizeClass={featureStyles.amc__circle_image}/>}
          <LazyLoader>
          {this.state.loaded && props.features.twoRowFeatures && <FeatureTwoRowComponent withSubtitle={false} features={props.features.twoRowFeatures.rowFeatures ? props.features.twoRowFeatures.rowFeatures : ''} className={`${featureStyles.grey__neutral}`} title={props.features.twoRowFeatures.title ? props.features.twoRowFeatures.title : ''} isGreyBackground={true} elementID={'two-row-features'} />}
          {!comparePlansExcept.includes(this.props.slug) && props.compare_plan && Object.keys(props.compare_plan).length > 0 && <ComparePlans compare_plan={props.compare_plan}/>}
          {props.testimonials && props.testimonials.length > 0 && <Testimonial testimonial={props.testimonials} router={props.router} slug={this.props.slug}/>}
          {props.blogs && props.blogs.length > 0 && <Blog key="wp-blog" blog={props.blogs?props.blogs:[]} title={`Handpicked Blogs on ${props.device}`} category={this.props.category}/>}
          {props.faqs && props.faqs.length !== 0 ? <Faq title="Frequently Asked Questions" questions={props.faqs} category={this.props.category} className="faq-primary" page_url={props.slug}/> : null}
          {props.cross_sell.length !== 0 ? (<CrossSell list={props.cross_sell} router={props.router} category={this.props.category}/>) : ''}
          {props.products ? <NotSureWhatLooking products={props.products} option={props.option} setOption={props.setOption} action={(e) => props.action(e)}/> : null}
        </LazyLoader>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    plans: state.plans
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHeaderData: (data: any) => dispatch(setHeaderData(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectionPlans);
