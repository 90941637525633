import React from 'react';
import {connect} from 'react-redux';
import styles from '../../styles/modules/components/device.module.scss';
import Button from '../common/ui-components/input/Button';
import {addToCart, toggleCartAlert} from '../../redux/actions/cartActions';

const CartAlert = (props:any) => {
  const getCartType = (type:string) => {
    switch(type) {
      case 'amc':
        return 'Repair and Services'
      case 'protection-plan':
        return 'Protection'
      case 'insta-repair':
        return 'Repair and Services'
      case 'homecare':
        return 'HomeCare'
      default:
        return 'other'
    }
  }

  const clearAndUpdateCart = () => {
    let cartData = props.cart.cartAlertData.data;
    props.toggleCartAlert({toggle:false, data:undefined})
    props.addToCart({...cartData, clear: true})
  }

  return (
    <React.Fragment>
      <div className={styles.cart_alert}>
        <h5 className={styles.cart_alert__title}>Want to Replace the Plan in Your Cart?</h5>
        <div className={styles.cart_alert__text}>
          Your cart contains {getCartType(props.cart.type)} {props.cart.quantity > 1 ? 'plans' : 'plan'}.
          Would you like to discard it and replace it with {getCartType(props.cart.cartAlertData?.data?.type)} plans?
        </div>
      </div>
      <div className={styles.selection_actions_common}>
          <Button
            onClick={() => {props.onClick()}}
            text="No"
          />
          <Button
            onClick={() => {clearAndUpdateCart()}}
            text="Yes"
          />
        </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state:any) => {
  return {
    cart: state.cart,
  }
}

const mapDispatchToProps = (dispatch:any) => {
  return {
    addToCart: (data:any) => dispatch(addToCart(data)),
    toggleCartAlert: (data:any) => dispatch(toggleCartAlert(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartAlert)
