import React, { Fragment } from 'react';
import Image from '../../../common/Image'
import styles from '../../../../styles/modules/ui-components/popover.module.scss';
import { isModalEscapeCloseDisabled, enableModalEscapeClose } from '../../../../utils/cookies';

interface UIModalProps {
    isOpen: boolean | false,
    onClick?: (e: React.MouseEvent) => void,
    children: React.ReactNode,
    larger?: boolean,
    xlarge?: boolean,
    onOverlayClick?: (e: React.MouseEvent) => void,
    className?:string,
    priority?:number, // 1 == hight | 0 == low
    boxClass?:any, // can be a string and style object
    isFullScreen?:any,
    isPlainBG?:boolean,
    isNonDismissable?: boolean,
    toast_info?: any,
    medium?:boolean,
    isRender?: boolean,
}

interface UIModalState {
    isOpen:boolean
}


interface UIModal {
    box_el:any,
    modal_overlay:any,
    root_EL:any
}

class UIModal extends React.Component<UIModalProps, UIModalState> {
    constructor(props) {
        super(props);
        this.box_el = React.createRef();
        this.modal_overlay = React.createRef();
        this.state = {
            isOpen:false
        }
        this.root_EL = null;
    }
    static getDerivedStateFromProps = (props:UIModalProps, state:UIModalState) => {
        if(state.isOpen !== props.isOpen) {
          return {
            isOpen:props.isOpen
          }
        }
        return null;
    }
    closeOnESC = (e:any) => {
        if(isModalEscapeCloseDisabled()){
            enableModalEscapeClose()
            return false
        }
        const esc = "Escape";
        if(e.key === esc) {
            this.props.onClick && this.props.onClick(e);
        }

    }
    setOverlayHeight = () => {
        
        let boxheight = this.box_el.current.scrollHeight,
            addExtra = boxheight > window.innerHeight;
            if(addExtra) {
                this.modal_overlay.current.style.height = boxheight +  50 + 'px';
                return false;
            }
        this.modal_overlay.current.style.height = boxheight  + 'px';
    }

    disabledScrollAndKeydown = () => {

        if(this.props.isOpen) {
            if(this.root_EL) {
                this.root_EL.style.overflowY = "hidden"; 
            }
            window.addEventListener('keydown', this.closeOnESC);
          }
          else {
            if(this.root_EL) {
                this.root_EL.style.overflowY = "auto" 
            }
             window.removeEventListener('keydown',this.closeOnESC);
          }

    }
    componentDidMount = () => {
        this.root_EL = document.documentElement;
        
    }
    componentDidUpdate = () => {
        
       this.setOverlayHeight();
       this.disabledScrollAndKeydown();

    }
    componentWillUnmount = () => {
        this.root_EL = null;
        window.removeEventListener('keydown',this.closeOnESC);
    }
    render() {
        const { isOpen, onClick, larger, xlarge, onOverlayClick, className, priority, boxClass, isPlainBG, isNonDismissable, medium, isFullScreen, isRender} = this.props;

        return (
            <Fragment>
                <div className={`${styles.app_popover} ${isNonDismissable ? styles.blocker_popover : ''} ${styles.app_popover_modal} ${className ? className : ''}`.trim()} data-open={isOpen ? isOpen.toString() : 'false'} data-large={larger ? larger : false} data-xlarge={xlarge} data-medium={medium} data-priority={priority ? priority : 0}>
                {this.props.toast_info && this.props.toast_info == 'An OTP has been sent to your email' && <div className={`${styles.__openToast}`} id="bottom-sticky-header">
                    <Image src={"/static/images/myprofile/updateCard.svg"} width="15px" height="15px" />
                    {this.props.toast_info}
                </div>}
                    <div className={`${styles.app_popover__el}`} ref={this.box_el}>
                        <div className={`${styles.app_popover__box} ${isPlainBG ? styles.plain : ''} ${isFullScreen ? styles.full_screen_popup : ''} ${boxClass ? boxClass : ''} ${isRender && styles.render_modal}`} >
                            {
                                !isNonDismissable && <a
                                href="#"
                                onClick={(e) => { e.preventDefault();(onClick ? onClick(e) : null); }}
                                className={styles.app_popover__close_btn}>
                                close
                                </a>
                            }
                            <div className={`${styles.app_popover__body} ${styles.app_popover__modal_body}`}>
                                {
                                    this.props.children
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.app_popover__overlay} ref={this.modal_overlay} onClick={(e) => {
                        onOverlayClick ? onOverlayClick(e) : null
                    }}>

                    </div>
                </div>
            </Fragment>
        );
    }

}

export default UIModal;
