import React from 'react';
import Section from './Section';
import styles from '../../styles/modules/components/compare.module.scss';
import Hidden from '@mui/material/Hidden';
import Router from 'next/router'
import { compareTableBuyNowLink } from '../../tracking/segment/planPage';
import { getPageURL } from '../../utils/tracking';



interface ComparePlansProps {
    compare_plan: any,
}



class ComparePlans extends React.Component<ComparePlansProps> {

    getMobileComponet = (items, index) => {
        const component = Object.keys(items)[0];
        const item = items[component];
        const links = this.props.compare_plan.buy_now_links;
        switch(component){
            case 'title':
                return (this.headerRowJSX(item.slice(1,), index))
            case 'row':
                const label = [item[0],"",""]
                item.slice(1,)
                return(
                    <div key={`mob-comp-lab-row-${index}`}>
                        {this.labelRowJSX(label,index)}
                        {this.RowJSX(item.slice(1,),index)}
                    </div>
                )
            case 'link':
                item.slice(1,)
                return (this.LinkRowJSX(item.slice(1,),index,links.slice(1,)))
        }
    }
    getDesktopComponet = (items, index) => {
        const component = Object.keys(items)[0];
        const item = items[component];
        const links = this.props.compare_plan.buy_now_links;

        switch(component){
            case 'title':
                return (this.headerRowJSX(item, index))
            case 'subtitle':
                return (this.labelRowJSX(item, index))
            case 'row':
                return (this.RowJSX(item, index))
            case 'link':
                return (this.LinkRowJSX(item,index,links))
        }
    }

    // component for header row in table
     headerRowJSX = (item, index) => {
        return(
                <div key={`cp-header ${index}`} className={styles._row}>
                    {item.map((value, ind) =>{
                        return (
                            <div key={`cp-header-val-${ind}`} className={styles._col}>
                                <h5>{value}</h5>
                            </div>
                        );
                    })}
                </div>
        );
    }

    // component for label row in jsx
     labelRowJSX = (item, index) => {

        return (
                <div key={`cp-label ${index}`} className={`${styles._row} ${styles._row_label}`}>
                    {item.map((value, ind) =>{
                        return (
                            <div key={`cp-label-val-${ind}`} className={styles._col}>
                                <label>{value}</label>
                            </div>
                        );
                    })}
                </div>
        )
    }

    // component for normal row data in jsx
     RowJSX = (item, index) => {
        return (
            <div key={`cp-row ${index}`} className={styles._row}>
                {item.map((value, ind) => {
                    return (
                        <div key={`cp-row-val-${ind}`} className={styles._col}>
                            {typeof(value)=='boolean'?<span data-icon className={value==true?styles.check:styles.cross}></span>:<span>{value}</span>}
                        </div>
                    )
                })}
            </div>
        )
    }

    // Link Row JSX
    LinkRowJSX = (item,index,links) => {
        return (
            <div key={`cp-row ${index}`} className={styles._row} style={{borderTop:'solid 1px rgba(171, 171, 171, 0.1)'}}>
                {item.map((value, ind) => {
                    return (
                        <div key={`cp-row-val-${ind}`} className={styles._col}>
                            <span className={value!=''?styles._link:''} onClick={() => { compareTableBuyNowLink({'target_link':links[ind]=='#user-selection'?getPageURL().page_link:links[ind],...getPageURL()}); this.takeToPlan(ind,links) }}> {value}   </span>
                        </div>
                    )
                })}
            </div>
        )
    }

    takeToPlan = (index,links) => {
        let elementIdOrUrl = links[index]

        if (elementIdOrUrl.includes('#')) {
            const a :any =document.querySelector(`input#device_price`)
            const b :any =document.querySelector(`#plan-selection`)
            b?.scrollIntoView({block: 'start'})
            a?.focus()
        } else if (elementIdOrUrl.includes('/')) {
            Router.push(elementIdOrUrl)
        }
    }

    getDeviceJSX = () => {
        const colStyle = this.props.compare_plan.styles;
        
            return (
                <Section title={`Compare ${this.props.compare_plan.plan_title} We Offer`} className="compare-plans" dataBg="shade-2" id="compare-plan" hasPadding={true}>
                    <div className="container">
                    <Hidden mdUp>
                        <div className={`${styles._table} ${styles[colStyle].includes('four')?styles.col_three:styles.col_two}`}>
                                {this.props.compare_plan.contents.map((item, index) =>{
                                    return this.getMobileComponet(item, index)
                                })}
                        </div>
                    </Hidden> 
                    <Hidden mdDown>
                        <div className={`${styles._table} ${styles[colStyle]}`}>
                            {this.props.compare_plan.contents.map((item, index) =>{
                                return this.getDesktopComponet(item, index)
                            })}
                        </div>
                    </Hidden> 

                    </div>
                </Section>
            );
        }
    render()
    {
        return Object.keys(this.props.compare_plan).length > 0 ? this.getDeviceJSX() : null;
    }
}


export default ComparePlans;