import React from 'react';
import Section from '../common/Section';
import styles from '../../styles/modules/components/device.module.scss';
import { connect } from 'react-redux'
import { readFaqLinkClick } from '../../tracking/segment/planPage';

interface DeviceDetailsSelectionProps {
    title?:string,
    benefits:{
      priority:number,
      text:string
    }[],
    PlanSelectionFields: any,
    productType: string,
    brands:string[],
    ac_types?:string[],
    wp_types?:string[],
    ac_capacities?:{value:string, text:string}[],
    router: any,
    without_coverage?:boolean,
    specificBenefits: any,
    plans:any,
    category?:string|null,
    category_id:string|number,
    device?:string,
}


class DeviceDetailsSelection extends React.Component<DeviceDetailsSelectionProps> {
    render() {
        const { title, benefits } = this.props;
        return(
            <Section className={styles.selection} id="plan-selection" key={this.props.router.asPath}>
                <div className={`${styles.selection__container} container`}>
                    <div className={`${styles.selection__row} row`}>
                        <div className={`${styles.selection__left} col-12 col-sm-12 col-md-12 col-lg-6`}>
                            <div className={styles.selection__features}>
                                <h2>{ title  }</h2>
                                <h4>Plan Benefits</h4>
                                <ul className="device_selection__feature__list">
                                    {this.props.plans.plan_specific_features !== undefined?
                                      (this.props.specificBenefits[this.props.plans.plan_specific_features] !== undefined ?
                                        this.props.specificBenefits[this.props.plans.plan_specific_features].map((item, index) => <li key={index}>{item.text}</li>)
                                        :
                                        '')
                                      :
                                      benefits.map((item, index) => <li key={index}>{item.text}</li>)
                                    }
                                </ul>
                                <div className={styles.selection__links}>

                                    {this.props.without_coverage ? null : <span>Not sure if we service your area? <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector("#cities")?.scrollIntoView({
                                            behavior: 'smooth', block: 'nearest',
                                        })
                                        }}> Check Covered Cities </a> </span>}

                                    <span className={styles.single_line}>Have a question? &nbsp;
                                        <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector("#faqs")?.scrollIntoView({
                                            behavior: 'smooth', block: 'nearest',
                                        })
                                        readFaqLinkClick({})
                                        }}> Read the FAQs</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.selection__right} col-12 col-sm-12 col-md-12 col-lg-6`}>
                            {/* <h2 className={styles.selection__mobile_heading}>{ title }</h2> */}
                            <this.props.PlanSelectionFields
                              productType={this.props.productType}
                              brands={this.props.brands}
                              ac_types={this.props.ac_types}
                              ac_capacities={this.props.ac_capacities}
                              router={this.props.router}
                              wp_types={this.props.wp_types}
                              category={this.props.category}
                              category_id={this.props.category_id}
                              device={this.props.device}
                            />
                        </div>
                    </div>
                </div>
            </Section>
        );
    }
}

const mapStateToProps = (state:any) => {
  return {
    plans: state.plans,
  }
}


export default connect(mapStateToProps)(DeviceDetailsSelection);
