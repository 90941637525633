import React, {Fragment} from 'react';
import UIDropdown from '../../../common/ui-components/input/UIDropdown/UIDropdown';
import Button from '../../../common/ui-components/input/Button';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { getParamsObjectFromString } from '../../../../utils';
import styles from '../../../../styles/modules/components/device.module.scss';
import {viewPlanClick} from '../../../../tracking/planPageTracking';

const DeviceDetails = (props:any) => {
  const [selectedWpType, setWpType] = React.useState<string>('option-RO')
  const handleChange = (option:string) => {
    setWpType(`option-${option}`)
    props.selectWpType(option)
  }
  const transitionType = isMobile ?
  {
    pageInitial: {
      opacity:0.9,
      translateX:0
    },
    pageAnimate: {
      opacity:1,
      translateX:0
    }
  }
  :
  {
    pageInitial: {
      translateX:-100
    },
    pageAnimate: {
      translateX:0
    }
  }

  React.useEffect(() => {
    let params:any = getParamsObjectFromString(props.router.asPath).urlParams
    if('device' in params) {
      props.wpTypes.forEach((type) => {
        if(params.device.includes(type)) {
          setWpType(`option-${type}`)
        }
      })
    }
  }, [])
  return (
    <Fragment>
      <motion.div  className={styles.selection__selection} initial="pageInitial" animate="pageAnimate" variants={transitionType}>
        <h5>Plans for Your Appliance </h5>
        <p>Enter details of your Water Purifier</p>
        <div className={styles.selection__inputs}>
          <div className={styles.selection__input_item}>
            <div className={styles.price_block_title__small}>Select Water Purifier Type</div>
            <div className={`${styles.selection__user__selected_price} ${styles.selection__user__selected_price_wp}`}>
              {props.wpTypes ? props.wpTypes.map((type) => {
                return (
                  <label className={styles.selected__price_block} data-price='' data-id={type} htmlFor={`option-${type}`} key={`option-${type}`}>
                    <input className="hidden-input" checked={selectedWpType===`option-${type}`} type="radio" name="wp-type" id={`option-${type}`} onChange={() => {handleChange(type)}}/>
                    <div className={`${styles.selected__price_block__details} ${styles.selected__price_block__details_wp}`}>
                      <span className={styles.price_block__normal}>{type} Purifier</span>
                    </div>
                  </label>
                )
              })
              :
              <div></div>
              }
            </div>
          </div>
          <div className={styles.selection__input_item}>
            <UIDropdown
              search
              onChange={props.selectBrand}
              options={props.brands ? props.brands.map((brand) => {
                  return {
                    key:brand,
                    value:brand,
                    text:brand,
                    icon: ''
                  }
                })
              : []}
            placeholder="Select Water Purifier Brand"
            selected_option={props.selectedBrand.value === null ? '' : props.selectedBrand}
            />
          </div>
          <div className={styles.selection_actions}>
              <Button disabled={!props.button_enabled} text="View Plans" inline={false}  variant="primary" loading={props.loading} onClick={(e) => {props.progressStep(e); viewPlanClick("AMC", "Water Purifier")}}/>
          </div>
        </div>
        </motion.div>
    </Fragment>
  )
}

export default DeviceDetails;
