import React from 'react';
import UIDropdown from '../../../common/ui-components/input/UIDropdown/UIDropdown';
import Button from '../../../common/ui-components/input/Button';
import styles from '../../../../styles/modules/components/device.module.scss';
import { motion } from 'framer-motion';
import { isMobile } from "react-device-detect";
import {viewPlanClick} from '../../../../tracking/planPageTracking';

interface Props {
  brands: string[],
  ac_types:string[],
  ac_capacities:{value:string, text:string}[],
  selectAcType: (value:any) => {},
  selectAcCapacity: (value:any) => {},
  selectAcBrand: (value:any) => {},
  progressStep: (value:any) => {},
  acType: {value:string, key:string, text:string},
  acCapacity: {value:string, key:string, text:string},
  acBrand: {value:string, key:string, text:string},
  button_enabled: boolean,
  loading:boolean
}

const DeviceDetails = (props:Props) => {
  const transitionType = isMobile ?
  {
    pageInitial: {
      opacity:0.9,
      translateX:0
    },
    pageAnimate: {
      opacity:1,
      translateX:0
    }
  }
  :
  {
    pageInitial: {
      translateX:-100
    },
    pageAnimate: {
      translateX:0
    }
  }
  return(

      <motion.div  className={styles.selection__selection} initial="pageInitial" animate="pageAnimate" variants={transitionType}>
        <div className={styles.selection__selection}>
            <h5>Plans for Your Appliance </h5>
            <p>Enter details of your Air Conditioner</p>
            <div className={styles.selection__inputs}>
                <div className={styles.selection__input_item}>
                    <UIDropdown
                    onChange={props.selectAcType}
                        options={props.ac_types ? props.ac_types.map((type) => {
                          return {
                            key:type,
                            value:type,
                            text:type,
                            icon: ''
                          }
                        })
                      : []}
                    placeholder="Select AC Type"
                    selected_option={props.acType.value === '' ? null : props.acType}
                    />
                </div>
                <div className={styles.selection__input_item}>
                    <UIDropdown
                    onChange={props.selectAcCapacity}
                        options={props.ac_capacities ? props.ac_capacities.map((type) => {
                          return {
                            key:type.text,
                            value:type.value,
                            text:type.text,
                            icon: ''
                          }
                        })
                      : []}
                    placeholder="Select AC Capacity"
                    selected_option={props.acCapacity.value === '' ? null : props.acCapacity}
                    />
                </div>
                <div className={styles.selection__input_item}>
                    <UIDropdown
                    search
                    onChange={props.selectAcBrand}
                        options={props.brands.map(brand => {return {key:brand, value:brand, text:brand, icon:''}})}
                    placeholder="Select AC Brand"
                    selected_option={props.acBrand.value === '' ? null : props.acBrand}
                    />
                </div>
                <div className={styles.selection_actions}>
                    <Button disabled={!props.button_enabled} loading={props.loading} text="View Plans" inline={false}  variant="primary"  onClick={(e) => {props.progressStep(e); viewPlanClick("AMC","Air Conditioner")}}/>
                </div>
            </div>
          </div>
      </motion.div>
  )
}

export default DeviceDetails
