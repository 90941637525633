import React,{Fragment, useEffect} from 'react';
import Head from 'next/head';
import Section from '../components/common/Section';
import Image from "next/legacy/image";
import router from 'next/router';


export async function getStaticProps() {
    return {
        props:{ pageName: '404' }
    }
}


const Error404 = () => {

    useEffect(()=> {
        let timer = setTimeout(()=>{  router.push(`/?ref=404+${window.location.pathname}`) }, 5000);
        return () => {
            clearTimeout(timer);
          };
    })
    
    return (
        <Fragment>
            <Head>
                <title>Page Not Found</title>
                <link rel="icon" href="../favicon.ico" />
            </Head>
            <Section hasPadding={true} className="error-no-found">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <Image
                                src="/static/images/404.svg"
                                width="500"
                                height="500"
                                alt="Page Not Found"
                                style={{
                                    maxWidth: "100%",
                                    height: "auto"
                                }} />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <h2>404 Page Not Found!</h2>
                            <p className="level-one">Uh oh, we can’t seem to find the page you’re looking for. <br/> Try going back to the previous page </p>
                            <p className="level-two">or visit home page <a href="/">here</a></p>
                        </div>
                    </div>
                </div>
            </Section>
        </Fragment>
    );
}


export default Error404;