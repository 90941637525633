import React from 'react';
import {GetProducts} from '../../../../api/plan';
import DeviceDetails from './DeviceDetails';
import PlanDetails from './PlanDetails';
import {appendQueryParams} from '../../utils';
import { toggleLoading, addToCart, toggleCartAlert, toggleResetStep, toggleRedirection } from '../../../../redux/actions/cartActions';
import { toggleSpecificFeatures, setCurrentStep, setAllPlans, setCrossSellPlans } from '../../../../redux/actions/planActions';
import { connect } from 'react-redux';
import { getParamsObjectFromString } from '../../../../utils';
import UIModal from '../../../common/ui-components/surface/UIModal';
import PlanAlert from '../../PlanAlert';
import {viewItem} from '../../../../tracking/ecommerceTracking';
import {selectAcCapacityClick, selectDeviceTypeClick, selectAcBrandClick, viewPlanClick, selectPlanClick, addToCartClick} from '../../../../tracking/planPageTracking';
import { getUtmParams, combineParamsString, getUtmString } from '../../../../utils';


import {formatDate} from '../../../../utils'

interface AcPlanSelectionProps {
  brands:string[],
  ac_types:string[],
  ac_capacities:{value:string, text:string}[],
  wp_types?:string[],
  category:string|null,
  category_id:string|number,
  router:any,
  cart: any,
  plans: {
    type:string,
    details: {
      acType?:string,
      acCapacity?:string,
      brand?:string,
      plan:any,
    }[],
    planStep:number,
    allPlans:any,
  },
  addToCart: (data:any) => void,
  toggleSpecificFeatures: (data:any) => void,
  setCurrentStep: (data:any) => void,
  setAllPlans: (data:any) => void,
  toggleCartAlert: (data:any) => void,
  toggleResetStep: (data:boolean) => void,
  toggleRedirection: () => void,
  setCrossSellPlans: (data:any) => void,
  loading:boolean,
  toggleLoading: (toggle:any) => void,
}

interface AcPlanSelectionState {
  current_step: 1 | number,
  ac_type:string,
  ac_capacity:string,
  ac_brand:string,
  error:boolean,
  plans:any,
  comprehensive:boolean,
  basic_amc:boolean,
  advanced_amc:boolean,
  selected_plan:any,
  planAlertOpen:boolean,
  amc_type: string,
  loading:boolean,
  amc_type_filled:boolean,
}

class AcPlanSelection extends React.Component<AcPlanSelectionProps,AcPlanSelectionState> {
  state:AcPlanSelectionState = {
    current_step: 1,
    ac_type:'',
    ac_capacity:'',
    ac_brand:'',
    error:false,
    plans:[],
    comprehensive:false,
    basic_amc:true,
    advanced_amc:false,
    selected_plan: null,
    planAlertOpen: false,
    amc_type: 'Basic AMC',
    loading:false,
    amc_type_filled:false,
  }

  componentDidMount = () => {
    this.setState({current_step:1})
    this.props.toggleRedirection()
    if(this.props.router.asPath.includes('amc_type')) {
      let queryParams = this.props.router.asPath.split('?')[1]
      try {
        let amc_type = queryParams.split('&').find(q => q.includes('amc_type')).split('=')[1].replace('%20', ' ').replace('#', '')
        this.props.toggleSpecificFeatures(amc_type.replace(' AMC', '').trim().toLowerCase())
        this.setState({amc_type: amc_type, amc_type_filled:true})
      } catch {
        this.props.toggleSpecificFeatures(undefined)
        this.setState({amc_type_filled:false})
      }
    } else {
      this.props.toggleSpecificFeatures(undefined)
    }
    const path = this.props.router.asPath
    if(path.split('?').length > 1) {
      this.prefillIfExists(path)
    }
  }

  componentDidUpdate = () => {
    if(this.props.cart.redirectToCart) {
      this.props.toggleRedirection()
      let redirectUrl = combineParamsString(`/cart/${this.props.cart.token_id}`, getUtmString())
      this.props.router.push(redirectUrl)
    }
  }

  componentWillUnmount = () => {
    this.props.toggleSpecificFeatures(undefined)
    this.props.setCurrentStep(1)
    this.props.setAllPlans([])
  }

  static getDerivedStateFromProps = (props, state) => {
      if(state.current_step !== props.plans.planStep){
        return {current_step:props.plans.planStep}
      }
      if(state.plans !== props.plans.allPlans) {
        return {plans:props.plans.allPlans, selected_plan:props.plans.allPlans.filter(product => product.product_type.includes('Comprehensive'))[0]}
      }
      return null
  }

  prefillIfExists = (path:string) => {
    let {urlParams} = getParamsObjectFromString(path)
    let ac_type = ''
    let ac_capacity = ''
    let ac_brand = ''
    if(urlParams['device'] !== undefined) {
      let device = urlParams['device'].replace('-', ' ')
      ac_type = device
    }

    if(urlParams['capacity'] !== undefined) {
      let capacity = urlParams['capacity']
      capacity = capacity === 'less-than-one-and-half-tons' ? '<=1.5' : '>1.5'
      ac_capacity = capacity
    }
    if(urlParams['brand'] !== undefined) {
      let brand = urlParams['brand']
      while(brand.includes('-')) {
        brand = brand.replace('-', ' ')
      }
      ac_brand = brand
    }
    this.setState({
      ac_type:ac_type,
      ac_capacity:ac_capacity,
      ac_brand:ac_brand,
    }, () => {
      this.attemptProgressStep(urlParams)
    })
  }

  attemptProgressStep = (splitParams:{[index:string]:string}) => {
    if(splitParams['view-plans'] !== undefined) {
      let viewPlans = splitParams['view-plans']
      if(viewPlans === 'true') {
        this.progressStep()
      }
    }
  }

  selectAcType = (value:any) => {
    this.setState({
      ac_type: value.value
    });
    selectDeviceTypeClick("AMC","Air Conditioner") // GTM TRIGGER - select Ac Type Click
    return false
  }

  selectAcCapacity = (value:any) => {
    this.setState({
      ac_capacity: value.value
    });
    selectAcCapacityClick("AMC","Air Conditioner") // GTM TRIGGER - select Ac Capacity Click
    return false;
  }

  selectAcBrand = (value:any) => {
    this.setState({
      ac_brand: value.value
    });
    selectAcBrandClick("AMC","Air Conditioner") // GTM TRIGGER - select Ac Brand Click
    return false;
  }

  progressStep = () => {
    // viewPlanClick("AMC","Air Conditioner") // GTM TRIGGER - view Plan Click
    this.setState({loading:true})
    if(this.state.ac_type !== '' && this.state.ac_capacity !== '' && this.state.ac_brand !== '') {
      GetProducts(
        {
          application:'website',
          channel_name:'website',
          category:this.state.ac_type,
          product_group:'amc',
          ac_capacity: this.state.ac_capacity,
        })
        .then((res:any) => {
          this.setState({loading:false})
          if (res.data) {
            if(res.data.products.length < 1) {
              this.setState({planAlertOpen:true})
            } else {
              if(!this.state.amc_type_filled) {
                this.props.toggleSpecificFeatures('basic')
              }
              this.setState({plans: res.data.products, current_step:2, error:false, selected_plan:res.data.products.filter(p => p.display_title == this.state.amc_type)[0]})
              this.props.setCurrentStep(2)
              this.props.setAllPlans(res.data.products)
              // this.props.router.push(appendQueryParams(this.props.router.asPath, {acType:this.state.ac_type, acCapacity:this.state.ac_capacity, acBrand:this.state.ac_brand}, this.state.current_step === 2), undefined, {shallow:true})
              window.history.replaceState(
                {
                  ...window.history.state,
                  as:appendQueryParams(this.props.router.asPath, {acType:this.state.ac_type, acCapacity:this.state.ac_capacity, acBrand:this.state.ac_brand}, true),
                  url:appendQueryParams(this.props.router.asPath, {acType:this.state.ac_type, acCapacity:this.state.ac_capacity, acBrand:this.state.ac_brand}, true),
                },
                '',
                appendQueryParams(this.props.router.asPath, {acType:this.state.ac_type, acCapacity:this.state.ac_capacity, acBrand:this.state.ac_brand}, true)
              )
            }
          } else {
            console.log('No data received')
          }
        })
        .catch((err) => {
          console.log(err)
          this.setState({loading:false})
          this.setState({error:true})
        });
    }
    return false;
  }

  selectPlan = (e:any, amc_type:string) => {
    e.preventDefault();
    let selectedPlan = this.state.plans.filter(p => p.display_title == amc_type)[0]
    this.props.toggleSpecificFeatures(amc_type.replace('AMC', '').trim().toLowerCase())
    this.setState({
      amc_type:amc_type,
      selected_plan:selectedPlan
    })
  }

  changeSelectedPlan = (plan:any, position?:number) => {
    this.setState({selected_plan:plan})
    viewItem({...plan, brand:this.state.ac_brand}, 'Plan Page', position)
    selectPlanClick("AMC","Air Conditioner") // GTM TRIGGER - select Plan Click
    return false;
  }

  addToCart = () => {
    addToCartClick("AMC","Air Conditioner") // GTM TRIGGER - add To Cart Click
    if(this.state.selected_plan) {
      let defaultStartDate = new Date;
      defaultStartDate.setDate(defaultStartDate.getDate() + 1)
      const data = {
        type: 'amc',
        token_id:this.props.cart.token_id,
        channel_name: 'website',
        item: {
          sku: this.state.selected_plan.sku,
          quantity: 1,
          device_detail: {
            brand:this.state.ac_brand
          },
          preferred_plan_start_date: formatDate(defaultStartDate)
        },
      }
      this.props.setCrossSellPlans(null)
      data['tracking_params'] = getUtmParams()
      this.props.toggleLoading('addToCartAmcAc')
      this.props.addToCart(data)
    }
    return false;
  }

  editPlan = () => {
    this.props.toggleSpecificFeatures(undefined)
    this.setState({current_step: 1})
    this.props.setCurrentStep(1)
    this.props.router.push(appendQueryParams(this.props.router.asPath, {acType:this.state.ac_type, acCapacity:this.state.ac_capacity, acBrand:this.state.ac_brand}, false), undefined, {shallow:true})
    return false;
  }

  render() {
    return (
      <React.Fragment>
        {this.state.current_step === 1 ?
          <DeviceDetails brands={this.props.brands}
          selectAcType={this.selectAcType}
          selectAcCapacity={this.selectAcCapacity}
          selectAcBrand={this.selectAcBrand}
          progressStep={this.progressStep}
          acType={{value:this.state.ac_type, text:this.state.ac_type, key:this.state.ac_type}}
          acCapacity={{value:this.state.ac_capacity, text:this.state.ac_capacity, key:this.state.ac_capacity}}
          acBrand={{value:this.state.ac_brand, text:this.state.ac_brand, key:this.state.ac_brand}}
          ac_types={this.props.ac_types}
          ac_capacities={this.props.ac_capacities}
          button_enabled={this.state.ac_type !== '' && this.state.ac_capacity !== '' && this.state.ac_brand !== ''}
          loading={this.state.loading}
          />
          :
          <PlanDetails
          ac_type={this.state.ac_type}
          ac_capacity={this.state.ac_capacity}
          ac_brand={this.state.ac_brand}
          comprehensive={this.state.comprehensive}
          advanced_amc={this.state.advanced_amc}
          basic_amc={this.state.basic_amc}
          amc_type={this.state.amc_type}
          selectPlan={this.selectPlan}
          plans={this.state.plans}
          changeSelectedPlan={this.changeSelectedPlan}
          addToCart={this.addToCart}

          editPlan={this.editPlan}
          loading={this.props.cart.loading==='addToCartAmcAc'}
          />
        }
        <UIModal isOpen={this.state.planAlertOpen} onClick={() => {this.setState({planAlertOpen:!this.state.planAlertOpen})}}>
          <PlanAlert onClick={() => {this.setState({planAlertOpen:!this.state.planAlertOpen})}} />
        </UIModal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state:any) => {
  return {
    cart:state.cart,
    plans:state.plans,
  }
}

const mapDispatchToProps = (dispatch:any) => {
  return {
    addToCart: (data:any) => dispatch(addToCart(data)),
    toggleSpecificFeatures: (data:string | undefined) => dispatch(toggleSpecificFeatures(data)),
    setCurrentStep: (data:any) => dispatch(setCurrentStep(data)),
    setAllPlans: (data:any) => dispatch(setAllPlans(data)),
    toggleCartAlert: (data:any) => dispatch(toggleCartAlert(data)),
    toggleResetStep: (data:boolean) => dispatch(toggleResetStep(data)),
    toggleRedirection: () => dispatch(toggleRedirection()),
    setCrossSellPlans: (data:any) => dispatch(setCrossSellPlans(data)),
    toggleLoading: (toggle:string) => dispatch(toggleLoading(toggle))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcPlanSelection)
