import React, { Fragment } from 'react';
import  styles from "../../../../../styles/modules/ui-components/google.module.scss";
import {LogSearchKeyword} from '../../../../../api/home';
import { deviceSearchClick, deviceSearchEmpty, deviceSearchItemClicked } from '../../../../../tracking/segment/homePage';
import { getPageURL } from '../../../../../utils/tracking';
import dynamic from 'next/dynamic';

const GoogleMobileSearch = dynamic(()=> import("./GoogleMobileSearch"),
  {loading: () => <></>,ssr:true }
);

interface GoogleSearchProps {
    options:{
        key:any,
        text:string,
        value:any,
        icon?:any,
        category?:string,
        keyword?:any
    }[],
    placeholder:string,
    selected_option?:any,
    onChange:(e)=> any,
    value?:any,
    search?:boolean,
    className?:string,
    labelClass?:string,
    dropDownClass?: any,
    onClick?:any,
    log?:any,
    hideMobile?:boolean,
    open?:boolean,
    setOpen?:any

}

interface GoogleSearchState {
    isOpen:boolean,
    options:{
        key:any,
        text:string,
        value:any,
        icon?:any,
        category?:string,
        keyword?:any,
    }[],
    selected_option:any,
    filteredOptions:{
        key:any,
        text:string,
        value:any,
        icon?:any,
        category?:string,
        keyword?:any
    }[],
    isSearching:boolean,
    isMobile:boolean,
    hideMobile:boolean,
    track:boolean,
    typedvalue:string,
    callEvent:boolean,
    previousSearchWord:String
}

class GoogleSearch extends React.Component<GoogleSearchProps,GoogleSearchState> {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.open ? true :false,
            options:this.props.options,
            selected_option:this.props.selected_option,
            filteredOptions:[],
            isSearching:false,
            isMobile:true,
            hideMobile: this.props.hideMobile ? true : false,
            track: true,
            typedvalue:'',
            callEvent: true,
            previousSearchWord:''
        }
    }

    static getDerivedStateFromProps = (props:GoogleSearchProps, state:GoogleSearchState) => {
        if(state.options !== props.options) {
            let data = {
                options:props.options,
            }
            if(state.selected_option !== props.selected_option) {
                data['selected_option'] = props.selected_option
            }
            return data
        }
        return null
      }

    openMenu = () => {
        if(!this.state.isOpen){
            deviceSearchClick({...getPageURL()})
        }
        this.setState({
            isOpen:true
        });
    }
    closeMenu = () => {     
        this.props.setOpen(false)
        this.setState({
            isOpen:false
        });
    }
    selectValue = (e) => {
        deviceSearchItemClicked({'search_string':e.target.getAttribute('data-value')}) // Segment Event
        let value = e.target.getAttribute('data-value'),
            selected_option_obj = this.state.options.find((option) => option.value == value);
            if(document.activeElement !== null) {
                (document.activeElement as HTMLElement).blur();
            }
            if(selected_option_obj) {
                this.setState({
                    selected_option:selected_option_obj
                });
                this.props.onChange(selected_option_obj);
            }
            this.props.setOpen(false)
            this.setState({
                isOpen:false,
                isSearching:false
            });
    }
    filterOptions = (e) => {
        const typed_value = e.target.value.trim();
        const removing_value = this.state.typedvalue.length > typed_value.length ? false : true;
        this.setState({typedvalue:typed_value})
        const filterFromKeyword = (options) => {
            const filter_options =  options.filter((option) => {
                var len = typed_value.length;
                var found = option.keyword && option.keyword.find((key)=> {
                    if(key.toLowerCase().replace(" ",'').slice(0,len).includes(typed_value.toLowerCase().replace(" ",'').slice(0,len))){   
                        return true
                        }
                    })
                if(found || option.text.toLowerCase().replace(" ",'').slice(0,len).includes(typed_value.toLowerCase().replace(" ",'').slice(0,len))){
                    return option
                }
            })

            filter_options.forEach((item,i)=>{
                var len = typed_value.length;
                if(item.text.toLowerCase().replace(" ",'').slice(0,len)==typed_value.toLowerCase().replace(" ",'').slice(0,len)){
                    filter_options.splice(i,1)
                    filter_options.unshift(item)
                }
            })
            return filter_options;
        }
        
        if(typed_value.length) {
            const filterOption = filterFromKeyword(this.state.options)
            this.setState({
                filteredOptions:filterOption,
                isSearching:true
            });
            if(filterOption.length == 0){
                if(this.state.track && removing_value){
                    this.setState({'track':false})
                    this.state.track && setTimeout(()=>{
                        if(!typed_value.startsWith(this.state.previousSearchWord)|| this.state.callEvent){
                            LogSearchKeyword({
                                "page":this.props.log?.page,
                                "DropDown":this.props.log?.dropdown,
                                "typed value":this.state.typedvalue,
                                "date":Date()
                            })
                        this.setState({callEvent:false})
                        deviceSearchEmpty({'search_string':this.state.typedvalue}) // Segment Event
                        }
                        this.setState({'track':true,previousSearchWord:this.state.typedvalue})
                    },1000);
                }
            }
            else{this.setState({previousSearchWord:this.state.typedvalue,callEvent:true})}
        }
        else {
            this.setState({
                isSearching:false
            });
        }
    }
    clearValue = (e) => {
        setTimeout(() => {
                e.target.value = "";
                this.setState({
                   isSearching:false
                });
        },500);
    }
    componentDidMount = () => {
        window.innerWidth < 481 ?
        this.setState({
            isMobile:true
        })
        :
        this.setState({
            isMobile:false
        });
        window.addEventListener('resize',() => {
            if(window.innerWidth > 481) {
                this.setState({
                    isMobile:false
                });
            }
            else {
                this.setState({
                    isMobile:true
                });
            }
        });
        if(this.props.open)
        {
            this.setState({isOpen:true})
        }
    }

    componentDidUpdate = () => {
        if(this.props.open && !this.state.isOpen)
        {
            this.setState({isOpen:true})
        }
    }

    render() {
        const { placeholder ,search, className, labelClass } = this.props;
        const { selected_option,options,filteredOptions,isSearching,isMobile ,isOpen  } = this.state;
        const dataObj = isSearching ? filteredOptions : options;
        return(
            <Fragment>
                {!this.state.hideMobile && <div role="listbox" aria-label="loction search box" id="search-box" data-home={true} tabIndex={0}  data-is-selected={selected_option ? true : false} data-search={search} className={`${styles.app_dropdown} ${isOpen ? styles.dropdown__open : ''} ${className ? className : ''}`.trim()}  onFocus={this.openMenu} onBlur={(e) => {
                    e.preventDefault();

                    !isMobile ?
                    this.closeMenu()
                    :
                    null
                }}>
                    <span title={placeholder} className={`${styles.app_dropdown__label} ${labelClass ? labelClass : ''}`.trim()}>{ selected_option ? selected_option.text : placeholder }</span>
                    { search && isOpen && isMobile == false && <input autoComplete='off' id="textbox" role="textbox" className={styles.app_dropdown__search} onBlur={this.clearValue} onChange={this.filterOptions} autoFocus placeholder="Type to search"/>}
                    {this.state.isOpen && <GoogleMobileSearch open={this.props.open} onClick={this.props.onClick} isMobile={isMobile} isOpen={isOpen} closeMenu={this.closeMenu} filterOptions={this.filterOptions} data={dataObj} isEmpty={dataObj.length == 0} selectValue={this.selectValue} selected_option={selected_option} dropDownClass={this.props.dropDownClass} {...this.props}/>}
                    
                { 
                    !isMobile && <div id="mobile-search" className={`${styles.app_dropdown__menu} ${styles.app_dropdown__mobile}  ${this.props.dropDownClass ? this.props.dropDownClass : ''.trim()}`} data-is-open={this.state.isOpen}>
                    {
                        <ul role="list">
                        {
                            dataObj && dataObj.map((option, index) => {                                    
                                return (
                                    <li
                                        role="listitem"
                                        className={`${styles.dropdown__menu_item} ${styles.dropdown__menu_item_mobile}`}
                                        title={option.text}
                                        data-selected={selected_option && selected_option.value == option.value ? true : false}
                                        onClick={this.selectValue}
                                        data-icon={option.icon}
                                        data-value={option.value}
                                        key={option.key}>
                                        <span> {option.text}</span>
                                        <small className={styles.device_type}> {option.category}</small>
                                    </li>
                                )
                            })
                        }
                        {
                            dataObj.length===0 &&
                            <span className={styles.dropdown__no_result}>No results found for your search </span>
                        }
                    </ul>
                    }
                    </div>
    }
                </div>
                }
                {this.state.hideMobile && this.state.isOpen && <GoogleMobileSearch open={this.props.open} hideSearch={this.props.hideMobile} onClick={(city)=>{this.props.onClick(city)}} isMobile={isMobile} isOpen={isOpen} closeMenu={this.closeMenu} filterOptions={this.filterOptions} data={dataObj} isEmpty={dataObj.length == 0} selectValue={this.selectValue} selected_option={selected_option} dropDownClass={this.props.dropDownClass}/>}
            </Fragment>
        );
    }
}

export default GoogleSearch;
