import React from 'react';
import {GetProducts} from '../../../../api/plan';
import DeviceDetails from './DeviceDetails';
import PlanDetails from './PlanDetails';
import {appendQueryParams} from '../../utils';
import {getDeviceFromSlug} from '../../../../utils';
import { toggleLoading,addToCart, toggleCartAlert, toggleResetStep, toggleRedirection } from '../../../../redux/actions/cartActions';
import { toggleSpecificFeatures, setCurrentStep, setAllPlans } from '../../../../redux/actions/planActions';
import { connect } from 'react-redux';
import { getParamsObjectFromString } from '../../../../utils';
import UIModal from '../../../common/ui-components/surface/UIModal';
import PlanAlert from '../../PlanAlert';
import {viewItem, selectItem} from "../../../../tracking/ecommerceTracking";
import {viewPlanClick,addToCartClick,selectPlanClick} from '../../../../tracking/planPageTracking';
import { getChannelName, getUtmParams, combineParamsString, getUtmString } from '../../../../utils';
import { addToCartClicked, devicePriceEdit, viewPlanClickedSegment } from '../../../../tracking/segment/planPage';
import { getPageURL, getProductName } from '../../../../utils/tracking';


class ProtectionPlans extends React.Component<any,any> {
  state:any = {
    current_step: 1,
    devicePrice: '',
    plans: [],
    selected_plan: {},
    error: false,
    planAlertOpen: false,
    loading:false,
    tracking_params: {},
  }

  componentDidMount = () => {
    this.setState({current_step:1})
    this.props.toggleRedirection()
    const path = this.props.router.asPath
    switch(this.props.productType) {
      case 'extended-warranty-plans':
        this.props.toggleSpecificFeatures('ew')
        break;
      case 'screen-protection-plans':
        this.props.toggleSpecificFeatures('sp')
        break;
      case 'damage-protection-plans':
        this.props.toggleSpecificFeatures('sdp')
        break;
      case 'iprotect':
        this.props.toggleSpecificFeatures('iprotect')
        break;  
      case 'total-protection':
        this.props.toggleSpecificFeatures('total-protection')
        break;    
      default:
        this.props.toggleSpecificFeatures(undefined)
        break;
    }
    if(path.split('?').length > 1) {
      this.prefillIfExists(path)
    }
  }

  componentDidUpdate = () => {
    switch(this.props.productType) {
      case 'extended-warranty-plans':
        if (this.props.plans.plan_specific_features !== 'ew') {
          this.props.toggleSpecificFeatures('ew')
        }
        break;
      case 'screen-protection-plans':
      if (this.props.plans.plan_specific_features !== 'sp') {
        this.props.toggleSpecificFeatures('sp')
      }
        break;
      case 'damage-protection-plans':
      if (this.props.plans.plan_specific_features !== 'sdp') {
        this.props.toggleSpecificFeatures('sdp')
      }
        break;
      case 'iprotect':
        if (this.props.plans.plan_specific_features !== 'iprotect') {
          this.props.toggleSpecificFeatures('iprotect')
        }
          break;  
      case 'total-protection':
        if (this.props.plans.plan_specific_features !== 'total-protection') {
          this.props.toggleSpecificFeatures('total-protection')
        }
          break;      
      default:
        this.props.toggleSpecificFeatures(undefined)
        break;
    }
    if(this.props.cart.resetStep) {
      this.props.toggleResetStep(false)
    }
    if(this.props.cart.redirectToCart) {
      this.props.toggleRedirection()
      let redirectUrl = combineParamsString(`/cart/${this.props.cart.token_id}`, getUtmString())
      this.props.router.push(redirectUrl)
    }
  }

  componentWillUnmount = () => {
    this.props.toggleSpecificFeatures(undefined)
    this.props.setCurrentStep(1)
    this.props.setAllPlans([])
  }

  static getDerivedStateFromProps = (props, state) => {
      if(state.current_step !== props.plans.planStep){
        return {current_step:props.plans.planStep}
      }
      if(state.plans !== props.plans.allPlans) {
        return {plans:props.plans.allPlans, selected_plan:props.plans.allPlans[0]}
      }
      return null
  }

  prefillIfExists = (path:string) => {
    let {urlParams} = getParamsObjectFromString(path)
    let device = ''
    if(urlParams['device_price'] !== undefined) {
      device = urlParams['device_price'].replace('-', ' ')
    }
    this.setState({
      devicePrice: device
    }, () => {
      this.attemptProgressStep(urlParams)
    })
  }

  attemptProgressStep = (splitParams:{[index:string]:string}) => {
    if(splitParams['view-plans'] !== undefined) {
      let viewPlans = splitParams['view-plans']
      if(viewPlans === 'true') {
        this.progressStep()
      }
    }
  }

  setDevicePrice = (e:any) => {
    this.setState({devicePrice: e.target.value.replace(/[^0-9]/gi, "")})
  }

  progressStep = () => {
    this.setState({loading:true})
    {
      if(this.state.devicePrice !== null) {
        let product_type = this.props.productType.replace('-', ' ').replace('-plans', '')
        if(this.props.category === "mobile-phones" && this.props.productType === "total-protection"){
          product_type = 'mobile total care'
        }
        else if(this.props.category === "laptops" && this.props.productType === "total-protection"){
          product_type = 'laptop total care'
        }
        GetProducts({
          application: 'website',
          channel_name: getChannelName(),
          product_group:'protection-plan',
          product_type: product_type,
          from_price:this.state.devicePrice,
          to_price:this.state.devicePrice,
          category_id:this.props.category_id,
        })
          .then((res:any) => {
            viewPlanClickedSegment({'device_price':this.state.devicePrice,...getPageURL(),'category':this.props.category,'product_type': this.props.productType})
            this.setState({loading:false})
            if (res.data) {
              if(res.data.products.length < 1) {
                this.setState({planAlertOpen:true})
              } else {
                this.props.setCurrentStep(2)
                this.props.setAllPlans(res.data.products)
                this.setState({plans: res.data.products, current_step:2, error:false, selected_plan:res.data.products[0]})
                // this.props.router.push(appendQueryParams(this.props.router.asPath, {device_price:this.state.devicePrice.toString()}, this.state.current_step === 2), undefined, {shallow:true})
                window.history.replaceState(
                  {
                    ...window.history.state,
                    as:appendQueryParams(this.props.router.asPath, {device_price:this.state.devicePrice.toString()}, true),
                    url:appendQueryParams(this.props.router.asPath, {device_price:this.state.devicePrice.toString()}, true),
                  },
                  '',
                  appendQueryParams(this.props.router.asPath, {device_price:this.state.devicePrice.toString()}, true)
                )
              }
            } else {
              //('No data received')
            }
          })
          .catch((err) => {
            console.log(err)
            this.setState({loading:false})
            this.setState({error:true})
          });
      }
    }
    // viewPlanClick(this.props.plans.plan_specific_features ? this.props.plans.plan_specific_features.toUpperCase() : '', this.props.device) // GTM TRIGGER - view Plan Click
    return false;
  }

  toggleDeviceButton = () => {
    return this.state.devicePrice === ''
  }

  changeSelectedPlan = (plan:any, position?:number) => {
    this.setState({selected_plan:plan})
    selectItem(plan)
    viewItem(plan)
    selectPlanClick(this.props.plans.plan_specific_features ? this.props.plans.plan_specific_features.toUpperCase() : '', this.props.device) // GTM TRIGGER - select Plan Click
    return false;
  }

  addToCart = () => {
    addToCartClick(this.props.plans.plan_specific_features ? this.props.plans.plan_specific_features.toUpperCase() : '', this.props.device) // GTM TRIGGER - add To Cart Click
    addToCartClicked({ 'sku_name':this.state.selected_plan.title, 'sku_price':this.state.selected_plan.price, 'previous_quantity':this.props.cart.quantity?this.props.cart.quantity:0, 'quantity':this.props.cart.quantity?this.props.cart.quantity+1:0+1, 'category':this.state.selected_plan.category, 'product_type':this.state.selected_plan.product_type, 'product_name':getProductName(this.state.selected_plan),product_group:'protection-plan'})
    if(this.state.selected_plan) {
      const data = {
        type: 'protection-plan',
        token_id:this.props.cart.token_id,
        channel_name: getChannelName(),
        item: {
          sku: this.state.selected_plan.sku,
          quantity: 1,
          device_detail: {
            device_price:this.state.devicePrice,
          },
        }
      }
      data['tracking_params'] = getUtmParams()
      this.props.toggleLoading('addToCartPP')
      this.props.addToCart(data)
    }
    return false;
  }

  editPlan = () => {
    devicePriceEdit({})
    this.props.toggleSpecificFeatures(undefined)
    this.setState({current_step: 1})
    this.props.setCurrentStep(1)
    this.props.router.push(appendQueryParams(this.props.router.asPath, {device_price:this.state.devicePrice.toString()}, false), undefined, {shallow:true})
    return false;
  }
  
  render() {

    return (
      <React.Fragment>
        {this.state.current_step === 1 ?
          <DeviceDetails
          setDevicePrice={this.setDevicePrice}
          devicePrice={this.state.devicePrice || ''}
          progressStep={this.progressStep}
          category={this.props.category}
          button_enabled={this.toggleDeviceButton()}
          loading={this.state.loading}
          journeyType={this.props.plans.plan_specific_features ? this.props.plans.plan_specific_features.toUpperCase() : ''}
          device={this.props.device? this.props.device : ''}
          router={this.props.router}
          />
        :
        <PlanDetails
        plans={this.state.plans}
        productType={this.props.productType}
        changeSelectedPlan={this.changeSelectedPlan}
        addToCart={this.addToCart}
        device_price={this.state.devicePrice}
        editPlan={this.editPlan}
        category={getDeviceFromSlug(this.props.category)}
        loading={this.props.cart.loading==='addToCartPP'}
        />
        }
        <UIModal isOpen={this.state.planAlertOpen} onClick={() => {this.setState({planAlertOpen:!this.state.planAlertOpen})}}>
          <PlanAlert onClick={() => {this.setState({planAlertOpen:!this.state.planAlertOpen})}} />
        </UIModal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state:any) => {
  return {
    cart:state.cart,
    plans:state.plans,
  }
}

const mapDispatchToProps = (dispatch:any) => {
  return {
    addToCart: (data:any) => dispatch(addToCart(data)),
    toggleSpecificFeatures: (data:string | undefined) => dispatch(toggleSpecificFeatures(data)),
    setCurrentStep: (data:any) => dispatch(setCurrentStep(data)),
    setAllPlans: (data:any) => dispatch(setAllPlans(data)),
    toggleCartAlert: (data:any) => dispatch(toggleCartAlert(data)),
    toggleResetStep: (data:any) => dispatch(toggleResetStep(data)),
    toggleRedirection: () => dispatch(toggleRedirection()),
    toggleLoading: (toggle:string) => dispatch(toggleLoading(toggle)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectionPlans);
