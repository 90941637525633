import Head from 'next/head'
import { useRouter } from "next/router";
import React from 'react'
import { GetPlanDetails} from '../api/plan';
import { Fragment } from 'react';
import PlanBundle from '../components/plan';
import CitySelector from '../components/plan/CitySelector'
import { CtaQuery } from '../components/home/CtaQuery';
import { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import useSWR from 'swr';
import dynamic from "next/dynamic";
import VisibilityManager from "../components/HOC/visibilityManager";
import {  GetProductsBuild} from '../api/plan';
import Config from '../config';
import { getStaticPath, setUtmCookies, getChannelName, getParamsObjectFromString } from '../utils';
import { redirectIfGenericPage, setSelectedCityInCookie } from '../utils/cities';
import {viewItemList} from '../tracking/ecommerceTracking';
import slug_mappings from '../data/slug_mappings'
import { WebsiteSchema, BreadCrumbSchema, ProductSchema, LocalBusinessSchema, FaqSchema } from '@/constants/schema'
import SchemaLoader from '@/components/hoc/SchemaLoader';
import coverage_data from '../data/coverage_data';
import product_comparison_data from '../data/product_comparison_data';
import products_list from '../data/products_list';
import page_data from '../data/page_data';
import Error404 from './404';
import plan_details from '../data/plan_details';

const UIModal = dynamic(()=> import('../components/common/ui-components/surface/UIModal'),
{loading: () => <p>Loading ...</p>,ssr:true }
);

// #TODO: If getStaticProps are removed then will remove getStaticPaths also
// export async function getStaticPaths() {
//   const plan_details = await GetPlanSlugs();
//   return {
//     paths: plan_details ? plan_details.data : [],
//     fallback: false
//   }
// }


const Fetcher = (context:any) => {

  const planDetails = plan_details[context.params.slug]
  const page_details = page_data[context.params.slug]

  const data = {
    productType: planDetails?.product_type || '',
    category: planDetails?.category || '',
    category_id: planDetails?.category_id ||'',
    device: planDetails?.device || '',
    brands: page_details?.page_meta?.brands || [],
    features: page_details?.page_meta?.features || [],
    ac_types: page_details?.page_meta?.AC_TYPES || [],
    ac_capacities: page_details?.page_meta?.AC_CAPACITIES || [],
    wp_types: page_details?.page_meta?.WP_TYPES || [],
    brands_display: page_details?.page_meta?.popularBrands || {},
    cities: page_details?.page_meta?.display_list || [],
    popular_localities: page_details?.page_meta?.popularLocalities || {},
    products: products_list || [],
    compare_plan: product_comparison_data[context.params.slug] || [],
    coverage_data:  coverage_data[context.params.slug] || {},
    faqs: page_details?.faqs || [],
    blogs: page_details?.blogs || [],
    cross_sell: page_details?.cross_sell || [],
    testimonials: page_details?.testimonials || [],
    bannerImageData: page_details?.banner_config || [],
    meta_tag_data: page_details?.meta_tag || [],
    template: page_details?.template || "",
    categories: page_details?.page_categories || "",
    page_meta: page_details?.page_meta || {},
    product_group: page_details?.page_product_group || {},
    sort_list: page_details?.page_meta?.sort_list || [],
    breadcrumb: page_details?.page_meta?.breadcrumb || [],
    slug_mapping: slug_mappings[context.params.slug] || {},
    is_valid_page: page_details ? true : false
  }
  return data;
};

const InstaFetcher = async (categories:any, product_group:any, build_type, city:string) => {
  let application = "website"
  let instaplans:any = null;
  
  if(build_type=="buildwebsite"){
    application = build_type;
    instaplans = await GetProductsBuild({
      application: application,
      channel_name: getChannelName(),
      exclude_type: 'Instarepair',
      category__in: categories,
      product_group__in: product_group,
      city:city
    })
  }
  var insta_plans = instaplans && instaplans.data ? instaplans.data.products : []
  return insta_plans
}

export async function getServerSideProps(context: any) {
  const data = await Fetcher(context)
  let instaplans:any = ""
  let build_type = "buildwebsite"
  if(build_type){
    if(data.page_meta.prefetcher){
      instaplans = await InstaFetcher(data.categories, data.product_group, build_type, data.slug_mapping.city)  
    }
  }
  return {
    props: {
      data: data,
      instaplans: instaplans,
      pageName: 'plan-page',
      layoutConfig: {}
    }
  }
}

const PlanPage = (props: any) => {
  const router = useRouter()
  const slug: any = router.query.slug;
  
  const [show, setShow] = useState(false);
  const [ind, changeInd] = useState(undefined);
  const [open, changeOpen] = useState(false);
  const [questions] = useState([]);
  const [option, setOption] = useState(null);
  const [disabled, changeDisabled] = useState(true)
  const [onetime,setOnetime] = useState(false)
  const [trackslug,settrackSlug] = useState("")
  const BASE_URL = Config.BASE_URL;
  const OG_IMAGE = getStaticPath(props.data?.bannerImageData?.og_image || "")
  let split:any = []
  let temp_slug = slug

  temp_slug.replace('-repair-service', '').split('-').forEach(i => {
    split.push(i.replace(i[0], i[0].toUpperCase()))
  })

  let categoryType = split.join(' ')
  categoryType = categoryType.slice(0, categoryType.length - 1)
  const {data:instaplans,mutate}:any = useSWR([categoryType,slug+",plan_page"],InstaFetcher,{
    initialData: props.instaplans,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })  

  if(slug.includes('-repair-service')){
    if(!onetime){
      mutate()
      setOnetime(true)
    }
  }
  if(trackslug !== categoryType){
    settrackSlug(categoryType)
  }  

  useEffect(() => {

    if(!localStorage.getItem('journey_starts_from')){
      // Setting localStorage parameter for GTM
      localStorage.setItem('journey_starts_from', 'direct')
    }
    if(props && props.data && props.data.questions && props.data.questions.length==1){
      router.push(props.data.questions[0].url)
    }
    let params:any = getParamsObjectFromString(window.location.href)['urlParams'];
    if(params['cityreset'])
    {
      setSelectedCityInCookie("")
    }
    redirectIfGenericPage(props.data?.slug_mapping, router)
    const timer = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => setUtmCookies(window.location.href), [props]);

  useEffect(()=>{
    let params:any = getParamsObjectFromString(window.location.href)['urlParams'];
    if(params['cityreset']) setSelectedCityInCookie("");
  })

  useEffect(() => {
    if(props?.data?.productType === "repair-service") {
      console.log("in slug", props)
      viewItemList(props.instaplans,"instarepair");
    }
    let params:any = getParamsObjectFromString(window.location.href)['urlParams'];
    if(params['cityreset'])
    {
      setSelectedCityInCookie("")
    }
    redirectIfGenericPage(props.data?.slug_mapping, router)
  },[props.data?.slug_mapping])
  
  let cached_props:any = props.data || {}

  const closeModal = () => {
    changeInd(undefined)
    changeDisabled(true)
    router.push('/')
    return false
  }

  const close = () => {
    changeInd(undefined)
    changeDisabled(true)
    changeOpen(false)
    setOption(null)
    return false
  }

  const notSure = (e) => {
    router.push(`/${router.query.slug}?question_popup=${e.value}`)
      window.scrollTo(0, 0)
      setOption(null)
  }


  if (!slug) {
    return null;
  }

  return (
    <motion.div initial="pageInitial" animate="pageAnimate" variants={{
      pageInitial: {
        opacity: 0
      },
      pageAnimate: {
        opacity: 1
      }
    }}
    >
    {cached_props.is_valid_page ? 
      <Fragment>
        <Head>
          <title>{cached_props?.meta_tag_data?.tag_title}</title>
          <link rel="icon" href="/favicon.ico" />
          <meta name="description" content={cached_props?.meta_tag_data?.tag_description}></meta>
          { props.data?.slug_mapping?.is_generic && <meta name="ROBOTS" content="NOINDEX"></meta>
          }
          
          <meta property="og:title" content={cached_props?.meta_tag_data?.tag_title}/>
          <meta property="og:description" content={cached_props?.meta_tag_data?.tag_description}/>
          <meta property="og:url" content={`${BASE_URL}${router.asPath}`}/>
          <meta property="og:image" content={OG_IMAGE}/>
          <link rel="canonical" href={`${BASE_URL}/${slug}`}></link>
          <SchemaLoader schema={WebsiteSchema} />
          { props.data.faqs.length !== 0 && <SchemaLoader schema={FaqSchema(props)} /> }
          { props.data.breadcrumb.length !== 0 && <SchemaLoader schema={BreadCrumbSchema(props)} /> }
          { props.data?.slug_mapping?.city && <SchemaLoader schema={LocalBusinessSchema(props)} /> }
          { ProductSchema(props) && ProductSchema(props).map(item => <SchemaLoader schema={item} /> )}
        </Head>
          <Fragment>
            <VisibilityManager>
              { () => <PlanBundle {...cached_props} slug={slug} router={router} option={option} setOption={setOption} action={notSure} insta_plans={props.instaplans} slug_mapping={props.data?.slug_mapping}/> }
            </VisibilityManager>
            <UIModal isOpen={open} onClick={close} larger={true} onOverlayClick={close}>
              <CtaQuery disabled={disabled} changeDisabled={changeDisabled} questions={questions} router={router} ind={ind} changeInd={changeInd} changeOpen={changeOpen} setOption={setOption} />
            </UIModal>
            <CitySelector slug_mapping={props.data?.slug_mapping} device_name={props.data.device} />
          </Fragment>
      </Fragment>
    : <Error404 /> }
    </motion.div>
  )
}

export default PlanPage
