import React from 'react'
import styles from '../../styles/modules/components/device.module.scss';
import Button from '../common/ui-components/input/Button';

const PlanAlert = (props:any) => {
  return (
    <React.Fragment>
    <div className={styles.cart_alert}>
      <h5 className={styles.cart_alert__title}>Uh Oh!</h5>
      <div className={styles.cart_alert__text}>
         Device Price entered is outside the plan purchase limit.
      </div>
    </div>
    <div className={styles.selection_actions_common}>
        <Button
          onClick={() => {props.onClick()}}
          text="OK"
        />
      </div>
    </React.Fragment>
  )
}

export default PlanAlert
