import React from 'react';
import MaintenanceAndServices from './MaintenanceAndServices';
import AirConditionerAmc from './AirConditionerAmc';
import ProtectionPlans from './ProtectionPlans';
import WaterPurifierAmc from './WaterPurifierAmc';
import Category from '../instarepair/planselection/category'
import MultiCategory from '../instarepair/planselection/multicategory'
import HomeCare from './HomeCare';

interface Map {
  [index:string]: (props?:any) => React.Component | React.ReactChild
}

export const PLAN_SELECTION_TYPE:Map = {
  service_repair : (props?:any) => <MaintenanceAndServices {...props}  />,
  amc_ac : (props?:any) => <AirConditionerAmc {...props}  />,
  protection_plan : (props?:any) => <ProtectionPlans {...props} />,
  amc_wp: (props?:any) => <WaterPurifierAmc {...props} />,
  instarepair: (props?:any) => <Category {...props} />,
  multi_insta: (props?:any) => <MultiCategory {...props} />,
  chimney_hob_plans: (props?:any) => <MaintenanceAndServices {...props} />,
  homecare: (props?:any) => <HomeCare {...props} />
}

// export const PLAN_SELECTION_TYPE:Map = {
//   service_repair : (props?:any) => <MaintenanceAndServices {...props}  />,
//   instarepair: (props?:any) => <MaintenanceAndServices {...props} />,
//   protection_plan : (props?:any) => <ProtectionPlans {...props} />,
//   multi_insta: (props?:any) => <MultiCategory {...props} />,
//   homecare: (props?:any) => <HomeCare {...props} />
// }
