import React, {Fragment} from 'react';
import Button from '../../../common/ui-components/input/Button';
import styles from '../../../../styles/modules/components/device.module.scss';
import { motion } from 'framer-motion';
import { isMobile } from "react-device-detect";
import {viewItemList} from '../../../../tracking/ecommerceTracking';
import {amcAcPlanTypeButton} from '../../../../tracking/planPageTracking';
import {formatNumber} from '../../../../utils/index';

interface Props {
  ac_type: string,
  ac_capacity:string,
  ac_brand:string,
  comprehensive:boolean,
  basic_amc:boolean,
  advanced_amc:boolean,
  amc_type:string,
  selectPlan:(e:any, amc_type:string) => void,
  plans:any,
  changeSelectedPlan: (value:any, position?:number) => {},
  addToCart: (value:any) => {},
  editPlan: () => {},
  loading:boolean
}

const PlanDetails = (props:Props) => {
  const groupPlans = (plans:any) => {
    let groupedPlans = {}
    if(plans.length > 0) {
      plans.forEach((plan:any) => {
        if(plan.display_title in groupedPlans) {
          groupedPlans[plan.display_title].push(plan)
        } else {
          groupedPlans[plan.display_title] = [plan]
        }
      })
    }
    return groupedPlans
  }
  const groupedPlans = groupPlans(props.plans)
  const [radioSelection, setRadioSelection] = React.useState<any>(`${props.amc_type}-option-0`)
  const handleChange = (option:string, plan:any, position?:any) => {
    setRadioSelection(option);
    props.changeSelectedPlan(plan, position);
  }

  React.useEffect(()=>{
    if(props.plans?.length!=0){
      viewItemList(props.plans)
    }
  },[props.plans])

  const compare = (a:any, b:any) => {
    if ( a.totalPrice < b.totalPrice ){
    return -1;
  }
  if ( a.totalPrice > b.totalPrice ){
    return 1;
  }
  return 0;
  }

  const sortAmcTypes = (amcTypes:any) => {
    let sortingCriteria:any = []
    amcTypes.forEach((type:any) => {
      // let tempCriteria:any = {}
      sortingCriteria.push ({
        type: type,
        totalPrice: groupedPlans[type].reduce((total, item) => {
          return total + item.mrp
        }, 0)
      })
      // sortingCriteria.push(tempCriteria)
    })
    sortingCriteria = sortingCriteria.sort(compare)
    let sortedTypes = sortingCriteria.map(item => item.type)
    return sortedTypes
  }

  const handleToggle = (e:any, option:string) => {
    setRadioSelection(`${option}-option-0`);
    props.selectPlan(e, option)
    amcAcPlanTypeButton(option,"AMC","Air Conditioner") // GTM TRIGGER -AC Plan Type Click
  }
  const transitionType = isMobile ?
  {
    pageInitial: {
      opacity:0.9,
      translateX:0
    },
    pageAnimate: {
      opacity:1,
      translateX:0
    }
  }
  :
  {
    pageInitial: {
      translateX:100
    },
    pageAnimate: {
      translateX:0
    }
  }
  return (

    <Fragment>
      <motion.div className={`${styles.selection__selection} ${styles.selected}`} initial="pageInitial" animate="pageAnimate" variants={transitionType}>
          <h5>Plans for Your Appliance <a href="#" onClick={(e:React.MouseEvent) => {e.preventDefault(); props.editPlan()}} className={styles.device_selection__edit} title="Edit selection"></a></h5>
          <div className={styles.selection__user__selected}>
              <div className={styles.selected_block}>
                  <span className={styles.selected_block__label}>AC Type</span>
                  <span className={styles.selected_block__value}>{props.ac_type}</span>
              </div>
              <div className={styles.selected_block}>
                  <span className={styles.selected_block__label}>AC Capacity</span>
                  <span className={styles.selected_block__value}>{props.ac_capacity === '<=1.5' ? 'less than 1.5 tons' : 'more than 1.5 tons'}</span>
              </div>
              <div className={styles.selected_block}>
                  <span className={styles.selected_block__label}>AC Brand</span>
                  <span className={styles.selected_block__value}>{props.ac_brand}</span>
              </div>
          </div>
          <div className={styles.selected_product_type}>
              <div className={styles.product__type_tab}>
                  {groupedPlans && Object.keys(groupedPlans).length > 0 && (
                    sortAmcTypes(Object.keys(groupedPlans)).map((key, index) => (
                      <a href="#" data-selected={props.amc_type === key} onClick={(e) => {handleToggle(e, key)}} key={index}>{key}</a>
                    ))
                  )}
                  {/*
                    <a href="#" data-selected={props.basic_amc} onClick={(e) => {handleToggle(e, 'basic-option-0')}}>Basic AMC</a>
                    <a href="#" data-selected={props.advanced_amc} onClick={(e) => {handleToggle(e, 'advanced-option-0')}}>Advanced AMC</a>
                    <a href="#" data-selected={props.comprehensive} onClick={(e) => {handleToggle(e, 'comprehensive-option-0')}} >Comprehensive AMC</a>
                  */}
              </div>

          </div>
          <motion.div className={styles.selection__user__selected_price}  initial={false} variants={{
          pageInitial: {
            opacity: 0
          },
          pageAnimate: {
            opacity: 1
          }
        }}>
          {/* map below*/}
            {groupedPlans && Object.keys(groupedPlans).length > 0 ?
              groupedPlans[props.amc_type] && groupedPlans[props.amc_type].map((plan, index) => (
                  <label className={styles.selected__price_block} data-price={plan.price} data-id={plan.product_sku} htmlFor={`${props.amc_type}-option-${index}`} key={`${props.amc_type}-option-${index}`}>
                    <input className="hidden-input" checked={radioSelection===`${props.amc_type}-option-${index}`} type="radio" name="plan-price" id={`${props.amc_type}-option-${index}`} onChange={() => {handleChange(`${props.amc_type}-option-${index}`, plan, index)}}/>
                    <div className={styles.selected__price_block__details}>
                      <span className={styles.price_block__original}>{plan.price === plan.mrp ? <></> : <>{formatNumber(plan.mrp)}</>}</span>
                      <span className={styles.price_block__discounted}>{formatNumber(plan.price)}</span>
                      <span className={styles.price_block__duration}>{plan.duration}</span>
                    </div>
                  </label>
              ))
              :
              <div></div>
            }
            {/*props.comprehensive &&
              props.plans.filter(product => product.product_type.includes('Comprehensive')).map((plan, index) => (
                <label className={styles.selected__price_block} data-price={plan.price} data-id={plan.product_sku} htmlFor={`comprehensive-option-${index}`} key={`comprehensive-option-${index}`}>
                  <input className="hidden-input" checked={radioSelection===`comprehensive-option-${index}`} type="radio" name="plan-price" id={`comprehensive-option-${index}`} onChange={() => {handleChange(`comprehensive-option-${index}`, plan)}}/>
                  <div className={styles.selected__price_block__details}>
                    <span className={styles.price_block__original}>{plan.price === plan.mrp ? <></> : <>{formatNumber(plan.mrp)}</>}</span>
                    <span className={styles.price_block__discounted}>{formatNumber(plan.price)}</span>
                    <span className={styles.price_block__duration}>{plan.duration}</span>
                  </div>
                </label>
              ))
              */}

              {/*props.basic_amc && props.plans.filter(product => product.product_type.includes('Annual')).map((plan, index) =>(
                <label className={styles.selected__price_block} data-price={plan.price} data-id={plan.product_sku} htmlFor={`basic-option-${index}`} key={`basic-option-${index}`}>
                  <input className="hidden-input" checked={radioSelection===`basic-option-${index}`} type="radio" name="plan-price" id={`basic-option-${index}`} onChange={() => {handleChange(`basic-option-${index}`, plan)}}/>
                  <div className={styles.selected__price_block__details}>
                    <span className={styles.price_block__original}>{plan.price === plan.mrp ? <></> : <>{formatNumber(plan.mrp)}</>}</span>
                    <span className={styles.price_block__discounted}>{formatNumber(plan.price)}</span>
                    <span className={styles.price_block__duration}>{plan.duration}</span>
                  </div>
                </label>
              ))
            */}
            {/*props.advanced_amc && props.plans.filter(product => product.product_type.includes('Advanced')).map((plan, index) => (
                <label className={styles.selected__price_block} data-price={plan.price} data-id={plan.product_sku} htmlFor={`advanced-option-${index}`} key={`advanced-option-${index}`}>
                  <input className="hidden-input" checked={radioSelection===`advanced-option-${index}`} type="radio" name="plan-price" id={`advanced-option-${index}`} onChange={() => {handleChange(`advanced-option-${index}`, plan)}}/>
                  <div className={styles.selected__price_block__details}>
                    <span className={styles.price_block__original}>{plan.price === plan.mrp ? <></> : <>{formatNumber(plan.mrp)}</>}</span>
                    <span className={styles.price_block__discounted}>{formatNumber(plan.price)}</span>
                    <span className={styles.price_block__duration}>{plan.duration}</span>
                  </div>
                </label>
              ))
            */}

            {/* map above*/}

          </motion.div>
          <div className={styles.note}>
          <span className={styles.compare} onClick={(e) => {
                                    e.preventDefault();
                                    // let offset = document.querySelector("#user-selection")?.clientHeight - 80;
                                    // window.scrollTo(0,offset)
                                    document.querySelector("#compare-plan")?.scrollIntoView({
                                        behavior: 'smooth', block: 'nearest',
                                    })
                                }}>Compare Plans</span>
              { (props.amc_type == 'Advanced AMC' || props.amc_type == 'Comprehensive AMC') ?
                (<p className={styles.text}>
                <em>Note</em>: Inspection will be conducted by Onsitego authorized personnel to confirm if the AC is in working condition.
                </p>) : (<p className={styles.text}>
                <em>Note</em>: AC should not be more than 10 years old.
                </p>)
              }

          </div>
          <div className={styles.selection_actions}>
              <Button text="Add to Cart" variant="primary" loading={props.loading} onClick={props.addToCart}/>
          </div>
      </motion.div>
    </Fragment>
  )
}

export default PlanDetails


// category lo addToCart
// api call with state props
// then make response and return and show in the
