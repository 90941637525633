import React, { Fragment} from 'react';
import dynamic from "next/dynamic";
import Section from '../../common/Section';
import Button from '../../common/ui-components/input/Button';
import Plans from './plans'
import { connect } from 'react-redux';
import MediaQuery from "react-responsive";
import { isMobile } from "react-device-detect";
import {makePlans} from "../utils";
import { showToast, toggleRedirection } from '../../../redux/actions/cartActions';
import { setScrollElementData } from '../../../redux/actions/planActions'
import styles from '../../../styles/modules/components/device.module.scss';
import {formatNumber, combineParamsString, getUtmString} from '../../../utils/index';
import {goToCart,selectDeviceTypeClick} from '../../../tracking/planPageTracking';
import { Hidden } from '@mui/material';
import CITIES_SLUG from '../../../city_constants';
import { goToCartClicked, productDetailScreenViewed, readFaqLinkClick } from '../../../tracking/segment/planPage';
import { getCartItemsSkuId } from '../../../utils/cart';
import { getPageURL, getPageViewData } from '../../../utils/tracking';
import { getCookieCitySlugMap } from '../../../utils/cities';
import { pageCallSegment } from '../../../utils/tracking/segmentTracking';
import { GetSkuDisplayConfig } from '../../../api/plan';
import { setSpecialCampaign } from '../../../utils/cookies';

const LazyLoader = dynamic(()=> import("@/hoc/LazyLoader"),
  { loading: () => <p>Loading ...</p>,ssr:true }
);

const Blog = dynamic(()=> import("../../common/Blog"),
{loading: () => <p>Loading ...</p>,ssr:true }
);

const Faq = dynamic(()=> import("../../common/Faq"),
{loading: () => <p>Loading ...</p>,ssr:true}
);

const Cities = dynamic(()=> import("../../cities-brands"),
{loading: () => <p>Loading ...</p>,ssr:true }
);

const Testimonial = dynamic(()=> import("../../common/Testimonials"),
{loading: () => <p>Loading ...</p>,ssr:true }
);

const CommonDeviceProblems = dynamic(()=> import("../../common/CommonDeviceProblems"),
{loading: () => <p>Loading ...</p>,ssr:true }
);


const FeatureImageBanner = dynamic(()=> import("../../common/FeatureImageBanner"),
{loading: () => <p>Loading ...</p>,ssr:true }
);

const CrossSell = dynamic(()=> import("../../../components/cross-sell/CrossSell"),
{loading: () => <p>Loading ...</p>,ssr:true }
);

const NotSureWhatLooking = dynamic(()=> import('../../../components/not-sure/NotSureWhatLooking'),
{loading: () => <p>Loading ...</p>,ssr:true }
);

const CityandBreadCrumb = dynamic(()=> import('../../common/CItyandBreadCrumb'),
{loading: () => <p>Loading ...</p>,ssr:true }
)

const BannerWithGraphic = dynamic(()=> import('../../../components/common/BannerWithGraphic'),
{loading: () => <p>Loading ...</p>,ssr:true }
)

const CrossProducts = dynamic(()=> import('../../common/CrossProducts'),
{loading: () => <p>Loading ...</p>,ssr:true }
)


interface categoryProps {
    plans: any,
    planStates: any,
    cart: any,
    navigation?: any
    features?: any,
    testimonials?: any[],
    blogs?: any[],
    category?: string, // required for common problems, in the category-slug form.
    faqs?: any[],
    bannerImageData?: any,
    setScrollElementData: (data: any) => void,
    router?: any,
    cross_sell?: any[],
    device?: string,
    headings?: string,
    toggleRedirection: () => void,
    products?:{cat_title:string, cat_slug:string, cat_type_slug:string, cat_type_title:string, group_name:string, group_slug:string, is_category_based:boolean, order:number}[],
    option?:any,
    setOption?:any,
    action?:any,
    insta_plans:any,
    page_meta:any,
    categories:any,
    breadcrumb:[],
    slug_mapping?:any,
    setShowToast: (data: any) => void,
    slug?:any,
    product_group?:any
}

interface state {
    plans: any
    category: string,
    planStates: any,
    actions: any,
    cart_total: any,
    cart_id: any,
    total_items:any,
    items:any,
    categories: string[]
    category_type_slug: any
    isFront: any,
    maxHeight:string,
    page_view:boolean,
    loaded: boolean,
    sku_display_config:{}
}

class category extends React.Component<categoryProps, any>{

    state:state = {
        ...this.props,
        category: "",
        category_type_slug: '',
        planStates: {
            order_total: 0
        },
        items:{},
        cart_total: 0,
        actions: [],
        cart_id : "",
        total_items: 0,
        isFront: false,
        plans: [],
        categories: [],
        maxHeight:'auto',
        page_view:false,
        loaded: false,
        sku_display_config:{}
    }

    planDict = (plans:any) => {
      let planDict = {}
      plans.forEach(plan => {
        if(planDict[plan.category] !== undefined) {
          planDict[plan.category].push(plan)
        } else {
          planDict[plan.category] = [plan]
        }
      });
      const orderedPlandict = Object.keys(planDict).sort().reduce(
        (obj, key) => {
          obj[key] = planDict[key];
          return obj;
        },
        {}
      );
      return orderedPlandict
    }

    makeCategories = (plans:any) => {
      let cat:any = {}
      plans.forEach((plan:any) => {
        if(cat[plan.category] === undefined) {
          cat[plan.category] = {}
        }
      })

      return Object.keys(cat).sort()
    }

    handleChange = (event) => {
        this.setState({ ...this.state, category: event.target.id })
        let category = event.target.id;
        if(event.target.id.includes('AC')){
            category = 'Air Conditioner';
        }else if(event.target.id.includes('Water Purifier')){
            category = 'Water Purifier';
        }
       selectDeviceTypeClick("Insta", category);
    }

    validateAndCheckout = () => {
        if (this.props.cart.items) {
            this.props.toggleRedirection()
            this.props.router.push(combineParamsString(`/cart/${this.props.cart.token_id}`, getUtmString()));
        } else {
        }
        goToCart("Insta", this.props.device ? this.props.device : '' ) // GTM TRIGGER - go To Cart
        goToCartClicked({...getPageURL(),'cart_amount':this.props.cart.amount, ...getCartItemsSkuId(this.props.cart.items)})
        return false
    }

    getCategory = () => {
        if(this.state.categories.length > 0) {
            let category_type_slug = this.state.categories[0]
            this.setState({category:category_type_slug})
        }
    }

    setPageView = (pageView) => {
        this.setState({
            page_view:pageView
        })
    }

    setOverflowHeight = () => {
        if(window.innerWidth <= 992) {
            this.setState({
                maxHeight:"auto"
            });
            return false;
         }
         else if(window.innerWidth > 992 && window.innerWidth <= 1400) {
             this.setState({
                 maxHeight:"auto"
             });
             // 386
             return false;
         }
         else {
             this.setState({
                 maxHeight:"auto"
             })
             //479
         }
    }

    handleScroll = () => {
        this.setState({loaded: true})
        window.removeEventListener('scroll', this.handleScroll);
    }
    componentDidMount = () => {
        var slug:any = this.props.router.query.slug

        let split:any = []
        slug.replace('-repair-service', '').split('-').forEach(i => {
          split.push(i.replace(i[0], i[0].toUpperCase()))
        })
        let categoryType = split.join(' ')
        categoryType = categoryType.slice(0, categoryType.length - 1)
        let categories:any = []
        categories = this.props.insta_plans && this.props.insta_plans.length > 0 ? this.makeCategories(this.props.insta_plans) : this.makeCategories(this.props.plans)
        this.setState({plans: this.props.insta_plans, category: this.props.insta_plans? this.makeCategories(this.props.insta_plans)[0] :{}, categories: categories})
        this.getCategory()
        if(!this.state.page_view){
            this.setPageView(true)
        }
        this.setOverflowHeight();
        window.addEventListener('scroll', this.handleScroll);

        
        if(!this.props.slug_mapping.is_generic){
            pageCallSegment("Product Detail - Screen Viewed")
            productDetailScreenViewed({'categories':this.props.categories.join(','), 'product_group':this.props.product_group.join(','), ...getPageViewData()})
          }
          if(this.props.slug_mapping.is_generic && !!!getCookieCitySlugMap(this.props.slug_mapping)){
            pageCallSegment("Product Detail - Screen Viewed")
            productDetailScreenViewed({'categories':this.props.categories.join(','), 'product_group':this.props.product_group.join(','), ...getPageViewData()})
          }
        GetSkuDisplayConfig({
            application: 'website'
          }).then((res:any)=>{
              this.setState({sku_display_config: res.data})
              setSpecialCampaign(res.data)
          })
    }


    componentDidUpdate() {
        if(this.getItemCount() !== this.state.total_items){
            var plans = makePlans(this.props.cart.items,this.state.plans)
            this.setState({plans: plans,total_items: this.getItemCount()})
        }
        if(this.props.planStates.showScrollAfterElement !== 'why-choose') {
            this.props.setScrollElementData('why-choose')
            }
    }

    componentWillUnmount = () => {

        if(this.state.page_view){
            this.setPageView(false)
        }
    }


    getItemCount = () => {
        let itemLength:any = 0
        if(this.props.cart.items && Object.keys(this.props.cart.items).length > 0 && this.props.cart.type === 'instarepair') {
            itemLength = 0
            Object.keys(this.props.cart.items).forEach((key) => {
            itemLength += this.props.cart.items[key].reduce((a, b) => ({quantity : a.quantity + b.quantity})).quantity
            })
        }
        return itemLength;
    }

    loadPlanStates = () => {
    }



    render() {
        let commonDeviceProblems = this.props.features?.commonDeviceProblems ? this.props.features?.commonDeviceProblems : {};
        let planBenefits = this.props.features.plan_selection ? this.props.features.plan_selection.benefits : {}
        let list_title = planBenefits.filter(item => item.isHeading)[0]
        let list_items = planBenefits.filter(item => !item.isHeading)
        const populat_localities_text = this.props.slug_mapping?.city && `We offer ${this.props.device} services in many areas in ${CITIES_SLUG[this.props.slug_mapping?.city].city}.`
        const sku_heading = this.props.features?.sku_heading
        return (
            <React.Fragment key={this.props.router.asPath}>
                <CityandBreadCrumb slug_mapping={this.props.slug_mapping} device_name={this.props.device} categories={this.props.categories ? this.props.categories : ''} breadcrumb={this.props.breadcrumb?this.props.breadcrumb:[]} setShowToast={this.props.setShowToast} cart={this.props.cart}/>
                <BannerWithGraphic slug_mapping={this.props.slug_mapping} cta_button_title={this.props.bannerImageData ? this.props.bannerImageData.cta_text : ''} title={this.props.bannerImageData ? this.props.bannerImageData.title : ''} subtitle={this.props.bannerImageData ? this.props.bannerImageData.subtitle : ''} banner_img={this.props.bannerImageData ? this.props.bannerImageData.image : ''} journeyType="Insta" categories={this.props.categories ? this.props.categories : ''} breadcrumb={this.props.breadcrumb?this.props.breadcrumb:[]}/>
                <Section className={`${styles.selection} ${styles.insta_repair}`} id="plan-selection" key={this.props.router.asPath}>
                    <div className={`${styles.selection__container} container`} key={this.props.router.asPath}>
                        <div className={`${styles.selection__row} row`}>
                            <Hidden mdDown>
                            <div className={`${styles.selection__left} col-12 col-sm-12 col-md-12 col-lg-6`}>
                                <div className={styles.selection__features}>
                                    {list_title && <h2 className={styles.benefits_heading} key={`benefit-heading`}>{list_title?.text}</h2>}
                                    {list_items && <ul key={`benefit-feature`} className={styles.selection__links}>
                                        {
                                            list_items.map((item, index) => <li className="device_selection__feature__list" key={`benefits-${index}`}>
                                            <h3>{item.text}</h3>
                                            </li>)  
                                        }
                                    </ul>
                                    }
                            <div className={styles.selection__links}>
                                {this.props.slug_mapping?.is_generic?
                                <span>
                                Not sure if we service your area?&nbsp;
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    document.querySelector("#cities")?.scrollIntoView({
                                    behavior: 'smooth', block: 'nearest', })
                                    }}> Check Covered Cities</a>
                                </span>:
                                <span>
                                {populat_localities_text}&nbsp;
                                    <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    document.querySelector("#localities")?.scrollIntoView({
                                        behavior: 'smooth', block: 'nearest', })
                                    }}> Check Popular Localities </a>
                                </span>
                                    }

                                    <span>
                                    Have questions on your mind?&nbsp;
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector("#faqs")?.scrollIntoView({
                                        behavior: 'smooth', block: 'nearest', })
                                        readFaqLinkClick({})
                                        }}> Read The FAQs</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Hidden>
                            <div className={`${styles.selection__right} col-12 col-sm-12 col-md-12 col-lg-6`}>
                                <h2 className={styles.selection__right__title_insta}>{sku_heading}</h2>
                                {this.state.plans && Object.keys(this.planDict(this.state.plans)).length > 1? (
                                    <h6 style={{ textAlign: "center" }}>Select the type of {this.state.plans ? this.state.plans[0].category_type : ""}</h6>) : ("")}

                                {this.state.plans && Object.keys(this.planDict(this.state.plans)).length > 1?
                                    (<div className={styles.selection__user__selected_price}>
                                        {
                                            this.state.categories.map((category_type, index) => {
                                                return (<Fragment key={`${index}-${category_type}`}>
                                                    <label key={`li-${index}`} className={styles.selected__price_block} htmlFor={category_type} >
                                                        <input className="hidden-input" type="radio" name="plan-price" id={category_type} onChange={this.handleChange} checked={this.state.category == category_type} />
                                                        <div className={styles.selected__price_block__details} style={{ textAlign: "center", width: "100%" }}>
                                                            <span className={styles.price_block__discounted} style={{ fontSize: "1rem" }}>{category_type}</span>
                                                        </div>
                                                    </label>
                                                </Fragment>)
                                            })}
                                    </div>) : (null)
                                }
                            {this.state.plans.length > 0 && <Plans maxHeight={this.state.maxHeight} plans={makePlans(this.props.cart.items, this.planDict(this.state.plans))[this.state.category]} planStates={this.state.plans} loadPlanStates = {this.loadPlanStates} setSelectedPlan={() => false} loadCart={()=>false} force={true} closeModal={() => {}} device={this.props.device ? this.props.device : ''} sku_display_config={this.state.sku_display_config}/>}

                            {/* mobile */}
                            {(this.props.cart.type === 'insta-repair' || this.props.cart.type === 'amc') && (
                            <MediaQuery maxDeviceWidth={992}>
                            <div className={`${styles.bottom_fixed} ${this.props.cart.items.length != 0 ? styles.open : ''}`.trim()} id="bottom-sticky-header">
                             <div className="row" style={{ display : "flex",alignItems : "center",justifyContent: "center",paddingLeft: "15px",paddingRight: "15px",paddingTop: "10px",paddingBottom: "10px"}}>
                                 <div className="col-6"  style={{verticalAlign: "middle",textAlign: "left", fontSize: "bolder"}}>
                                     <h6>Total: {formatNumber(this.props.cart.amount? this.props.cart.amount : 0)}</h6>
                                     {/* <span style={{fontSize: "12px"}}> {this.props.cart.quantity} services selected</span> */}
                                     <span style={{fontSize: "12px"}}> {this.props.cart.quantity} {this.props.cart.quantity == 1 ? 'service selected' : 'services selected'}</span>
                                 </div>

                                 <div className="col-6">
                                    <Button text="Go to Cart" variant="primary"  disabled={Object.keys(this.props.cart.items).length ? false : true} onClick={this.validateAndCheckout}/>
                                 </div>
                             </div>
                             </div>
                             </MediaQuery>)
                            }
                            {/* desktop */}
                            {(this.props.cart.type === 'insta-repair' || this.props.cart.type === 'amc')  && (
                            <MediaQuery minDeviceWidth={992}>
                             <div className={styles.bottom_cta}>
                                <div className="row" style={{ display : "flex",alignItems : "center",justifyContent: "center"}}>
                                    <div className={`col-7 col-sm-7 col-md-7 col-lg-7`}  style={{verticalAlign: "middle",textAlign: "left", fontSize: "bolder"}}>
                                        <h6 >Total: {formatNumber(this.props.cart.amount? this.props.cart.amount : 0)}</h6>
                                        {/* <span> ( {this.props.cart.quantity} services selected )</span> */}
                                        <span> ( {this.props.cart.quantity} {this.props.cart.quantity == 1 ? 'service selected' : 'services selected'} )</span>
                                    </div>

                                    <div className="col-5">
                                        <Button text="Go to Cart" variant="primary"  disabled={Object.keys(this.props.cart.items).length ? false : true} onClick={this.validateAndCheckout}/>
                                    </div>
                                </div>
                                </div>
                                </MediaQuery>)
                                }
                            </div>
                            <Hidden mdUp>
                            <div className={`${styles.selection__left} col-12 col-sm-12 col-md-12 col-lg-6`}>
                                <div className={styles.selection__features}>
                                    {list_title && <h2 className={styles.benefits_heading} key={`benefit-heading`}>{list_title?.text}</h2>}
                                    {list_items && 
                                        <ul key={`benefit-feature`} className={styles.selection__links}>
                                            {list_items.map((item, index) => 
                                                <li className="device_selection__feature__list" key={`benefits-${index}`}>
                                                    <h3>{item.text}</h3>
                                                </li>)
                                            }
                                        </ul>
                                    }
                                    <div className={styles.selection__links}>
                                    {this.props.slug_mapping?.is_generic?
                                    <span>
                                        Not sure if we service your area?&nbsp;
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            document.querySelector("#cities")?.scrollIntoView({
                                            behavior: 'smooth', block: 'nearest', })
                                            }}> Check Covered Cities</a>
                                        </span>:
                                        <span>
                                        {populat_localities_text}&nbsp;
                                            <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            document.querySelector("#localities")?.scrollIntoView({
                                                behavior: 'smooth', block: 'nearest', })
                                            }}> Check Popular Localities </a>
                                        </span>
                                        }
                                        <span>Have questions on your mind? <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector("#faqs")?.scrollIntoView({
                                            behavior: 'smooth', block: 'nearest',
                                            })
                                        readFaqLinkClick({})
                                        }}> Read The FAQ's </a></span>

                                    </div>
                                </div>
                            </div>
                            </Hidden>
                        </div>
                    </div>
                </Section>
                <LazyLoader>
                <div className={this.props.page_meta.cross_products ? 'odd-even-start-with-white' : 'odd-even-start-with-gray'}>
                    {this.state.loaded && this.props.page_meta.cross_products && <CrossProducts cross_products = {this.props.page_meta.cross_products}/>}
                   {this.state.loaded && <FeatureImageBanner title={this.props.features.whyChooseOnsitego !== undefined ? this.props.features.whyChooseOnsitego.title : ''} withSubtitle={false} features={this.props.features.whyChooseOnsitego !== undefined ? this.props.features.whyChooseOnsitego.steps : ''} className={`${styles.grey__neutral}`} isGreyBackground={isMobile == false} elementID='why-choose' />}
                    {this.props.testimonials && this.props.testimonials.length !== 0 && <Testimonial heading={this.props.page_meta.testimonial_heading} testimonial={this.props.testimonials} category={this.props.category}/>}
                    {Object.keys(commonDeviceProblems).length!=0 &&
                        <CommonDeviceProblems
                            problems={commonDeviceProblems.problems}
                            title={commonDeviceProblems.title}
                            description={commonDeviceProblems.description}
                            page_url={this.props.slug}
                            category={this.props.category}
                            />
                    }
                    {this.props.faqs && this.props.faqs.length !== 0 ? <Faq category={this.props.category} title="Frequently Asked Questions" questions={this.props.faqs} className="faq-primary" page_url={this.props.slug}/> : null}
                    <Cities headings="InstaRepair" {...this.props} />
                    {this.props.cross_sell && this.props.cross_sell.length !== 0 ? (<CrossSell list={this.props.cross_sell} router={this.props.router} category={this.props.category}/>) : ''}
                    {this.props.blogs && this.props.blogs.length !== 0 && <Blog key="ac-blog" blog={this.props.blogs?this.props.blogs:[]} title={`Handpicked Blogs on ${this.props.slug_mapping?.device_name}`} category={this.props.category}/>}
                    {this.props.products ? <NotSureWhatLooking products={this.props.products} option={this.props.option} setOption={this.props.setOption} action={(e) => this.props.action(e)}/> : null}
                </div>
                </LazyLoader>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state: any) => {
    return {
        planStates: state.instarepairplans,
        cart: state.cart
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        toggleRedirection: () => dispatch(toggleRedirection()),
        setScrollElementData: (data:any) => dispatch(setScrollElementData(data)),
        setShowToast: (data:any) =>dispatch(showToast(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(category)
