import React from 'react';
import AcPlanSelection from './AmcAC';
import ProtectionPlans from './ProtectionPlans';
import WpPlanSelection from './AmcWP';
import HomeCarePlanSelection from './HomeCare';

interface Map {
  [index:string]: (data?:any) => React.Component | React.ReactChild
}

export const PLAN_SELECTION_TYPE:Map = {
  amc: (data?:any) => <AcPlanSelection {...data} />,
  ew: (data?:any) => <ProtectionPlans {...data} />,
  wp: (data?:any) => <WpPlanSelection {...data} />,
  hc: (data:any) => <HomeCarePlanSelection {...data} />
}
