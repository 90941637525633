import React, {Fragment} from 'react';
import UITextField from '../../../common/ui-components/input/UITextField';
import Button from '../../../common/ui-components/input/Button';
import { motion } from 'framer-motion';
import styles from '../../../../styles/modules/components/device.module.scss';
import { isMobile } from 'react-device-detect';
import {getDeviceFromSlug, getSingularCategory} from '../../../../utils';
import {devicePriceTrigger, viewPlanClick} from '../../../../tracking/planPageTracking';

const DeviceDetails = (props:any) => {
  const transitionType = isMobile ?
  {
    pageInitial: {
      opacity:0.9,
      translateX:0
    },
    pageAnimate: {
      opacity:1,
      translateX:0
    }
  }
  :
  {
    pageInitial: {
      translateX:-100
    },
    pageAnimate: {
      translateX:0
    }
  }
  const onBlurDevicePrice = () => {
    devicePriceTrigger(props.journeyType, props.device);
  }
  const onKeyUp=(e)=>{
    if(!props.button_enabled && (e.charCode === 13 || e.key==='Enter')){
      props.progressStep();
      viewPlanClick(props.journeyType, props.device)
    }    
  } 
  return (
    <Fragment>
      <motion.div  className={styles.selection__selection} initial="pageInitial" animate="pageAnimate" variants={transitionType}>
          <h5>Explore Plans for your device </h5>
          <p>Enter price of your {getSingularCategory(getDeviceFromSlug(props.category))}</p>
          <div className={styles.selection__inputs} >
              <div className={styles.selection__input_item} style={{textAlign:'start'}}>
                <UITextField  id="device_price" onKeyUp={(e)=>onKeyUp(e)}  noLabel={true} className={styles.input} value={props.devicePrice.replace(/[^0-9]/gi, '')} type="tel" onChange={(e:any):void => {props.setDevicePrice(e)}} label="Device Price" isRequired={true} onBlur={()=>{onBlurDevicePrice()}}/> {/* GTM TRIGGER - device Price */}
              </div>
              <div className={styles.selection_actions}>
                  <Button text="View Plans" loading={props.loading} inline={false}  variant="primary"  onClick={() => {props.progressStep();viewPlanClick(props.journeyType, props.device)}} disabled={props.button_enabled}/>
              </div>
          </div>
      </motion.div>
    </Fragment>
  );
}

export default DeviceDetails
