import React from "react";
import Button from '../../../common/ui-components/input/Button';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import styles from '../../../../styles/modules/components/device.module.scss';
import {viewItemList} from "../../../../tracking/ecommerceTracking";
import {formatNumber} from '../../../../utils/index';

const PlanDetails = (props:any) => {
  const [radioSelection, setRadioSelection] = React.useState<string>('option-0')
  const handleChange = (option:string, plan:any, position?:number) => {
    setRadioSelection(option);
    props.changeSelectedPlan(plan, position);
  }
  React.useEffect(()=>{
    if(props.plans?.length!=0){
      viewItemList(props.plans)
    }
  },[props.plans])
  const transitionType = isMobile ?
  {
    pageInitial: {
      opacity:0.9,
      translateX:0
    },
    pageAnimate: {
      opacity:1,
      translateX:0
    }
  }
  :
  {
    pageInitial: {
      translateX:100
    },
    pageAnimate: {
      translateX:0
    }
  }
  return (
    <motion.div  className={`${styles.selection__selection} ${styles.selected} ${styles.selection_center}`} initial="pageInitial" animate="pageAnimate" variants={transitionType}>
      <h5>Plans for Your Appliance <a href="#" onClick={(e:React.MouseEvent) => {e.preventDefault(); props.editPlan()}} className={styles.device_selection__edit} title="Edit selection"></a></h5>

      <div className={styles.selection__user__selected}>
        <div className={styles.selected_block}>
          <span className={styles.selected_block__label}>Water Purifier Type</span>
          <span className={styles.selected_block__value}>{props.wpType}</span>
        </div>
        <div className={styles.selected_block}>
          <span className={styles.selected_block__label}>Water Purifier Brand</span>
          <span className={styles.selected_block__value}>{props.brand}</span>
        </div>
      </div>

      <div className={styles.selection__user__selected}>
        <span className={styles.selected_block__value}>Comprehensive AMC Plan details</span>
      </div>

      <div className={styles.selection__user__selected_price}>
        {props.plans.map((plan, index) => {
            return (
              <label className={styles.selected__price_block} data-price={plan.price} data-id={plan.product_sku} htmlFor={`option-${index}`} key={`option-${index}`}>
                <input className="hidden-input" checked={radioSelection===`option-${index}`} type="radio" name="plan-price" id={`option-${index}`} onChange={() => {handleChange(`option-${index}`, plan, index)}}/>
                <div className={styles.selected__price_block__details}>
                  <span className={styles.price_block__original}>{plan.price === plan.mrp ? <></> : <>{formatNumber(plan.mrp)}</>}</span>
                  <span className={styles.price_block__discounted}>{formatNumber(plan.price)}</span>
                  <span className={styles.price_block__duration}>{plan.duration}</span>
                </div>
              </label>
            )
          })
        }
      </div>
      <div className={styles.selection_actions}>
          <Button text="Add to Cart" variant="primary"  loading={props.loading} onClick={props.addToCart}/>
      </div>
    </motion.div>
  )
}

export default PlanDetails;
